import { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { Input, InputProps } from '../Input';

export interface ColorInputProps {
  name: string;
  title: ReactNode;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  error?: string | boolean;
  id?: string;
}

const StyledInput = styled.input`
  border: 1px solid ${colors.secondaryBorder};
  background-color: ${colors.white};
  cursor: pointer;
  height: 36px;
  width: 36px;
  ::-webkit-color-swatch {
    border-color: transparent;
  }
`;

const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: ${units(323)};
  gap: ${units(5)};
  font-weight: ${FontWeight.REGULAR};

  ${typography(10)};
`;

export const ColorInput = ({
    name,
    title,
    value,
    onChange,
    className,
    error,
    id,
}: ColorInputProps & InputProps) => (
    <StyledLabel className={className}>
        <Input id={id} name={name} error={error} title={title} placeholder={title} value={value?.toUpperCase()} onChange={onChange} />
        <StyledInput
            id={id}
            name={name}
            type="color"
            value={value}
            onChange={onChange}
        />
    </StyledLabel>
);
