import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled, { css } from 'styled-components';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { units } from '../../helpers/styles/units';
import { fontName } from '../GlobalStyle';
import { colors } from '../../constants/Colors';
import { Goods } from '../../types/Endpoints/orders/OrdersContracts';
import DefaultImage from '../../styles/images/products/default.png';
import CertificateImage from '../../styles/images/orders/certifacate.png';
import { EmptyRow } from '../EmptyRow';
import { config } from '../../config';
import { typography } from '../../helpers/styles/typography';
import { ProductTitle } from '../Orders/ProductTitle';
import { ProductProperties } from '../Orders/ProductProperties';

interface TableProps {
  className?: string;
  goods: Goods[];
  isCertificate?: boolean;
}

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
        '& .cell': {
            fontSize: '12px',
            lineHeight: '18px',
            fontFamily: fontName,
            color: colors.greyDark,
        },
        '& .text-grey': {
            color: colors.grey20,
        },

        '& .cell-first': {
            paddingLeft: '0',
            paddingRight: '0',
        },

        '& .status-align': {
            verticalAlign: 'top',
        },
    },
};

const StyledCell = styled(TableCell)`
  .status {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};
  }

  & > div {
    ${props => props.className?.includes('active')
      && css`
        background-color: rgba(87, 198, 118, 0.05);

        & div {
          background-color: #6db180;
        }
      `}

    ${props => props.className?.includes('inactive')
      && css`
        background-color: rgba(231, 72, 72, 0.05);

        & div {
          background-color: #e74848;
        }
      `}
  }
`;

const StyledStatusInfo = styled.div`
  width: ${units(4)};
  height: ${units(4)};
`;

const StyledImage = styled.img`
  height: ${units(24)};
  width: ${units(24)};
  margin: 0 auto;
  display: block;

  border-radius: ${units(0)};

  object-fit: contain;
  object-position: center;

  ${props => props.className?.includes('inactive')
    && css`
      opacity: 0.7;
    `}
`;

const StyledDiv = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
  gap: ${units(4)};
  width: ${units(70)};
`;

const TableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(4)};
`;

const StyledPrice = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  ${typography(2)};
`;

const StyledText = styled.div`
  font-weight: ${FontWeight.REGULAR};
  ${typography(2)};

  display: flex;
  flex-direction: column;
  gap: ${units(4)};

  ${props => props.className === 'inactive'
    && css`
      color: ${colors.grey20};
    `}
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(8)};
`;

export const OrderProductsTable = ({
    goods,
    isCertificate,
}: TableProps): JSX.Element => (
    <TableContainer component={Paper} sx={styles.root}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
            <TableHead sx={styles.root}>
                <TableRow
                    sx={{
                        'td, th': {
                            border: 0,
                            fontWeight: FontWeight.BOLD,
                            fontSize: '14px',
                            lineHeight: '18px',
                            height: '64px',
                        },
                    }}
                >
                    <TableCell align="left">&nbsp;</TableCell>
                    <TableCell>Информация о товаре</TableCell>
                    <TableCell align="left">Статус</TableCell>
                </TableRow>
            </TableHead>
            <TableBody sx={[styles.root, styles.body]}>
                {goods?.length > 0 ? (
                    goods.map(row => (
                        <TableRow key={row.id} sx={{ 'td, th': { border: 0 } }}>
                            <TableCell
                                align="left"
                                sx={{ width: '10%', minWidth: '80px' }}
                                className="cell cell-first"
                            >
                                <TableContentWrapper>
                                    {!isCertificate && (
                                        <StyledImage
                                            className={row.isActive ? 'image' : 'image inactive'}
                                            src={
                                                row?.pictures?.length && row?.pictures[0].pictureURL
                                                    ? `${config.imageUrlProd}${row?.pictures[0].pictureURL}`
                                                    : DefaultImage
                                            }
                                            alt={row.fullName}
                                        />
                                    )}
                                    {isCertificate && (
                                        <StyledImage
                                            className={row.isActive ? 'image' : 'image inactive'}
                                            src={CertificateImage}
                                            alt="Подарочный сертификат"
                                        />
                                    )}
                                    <StyledText className={row.isActive ? '' : 'inactive'}>
                                        {row.quantity} шт.
                                    </StyledText>
                                </TableContentWrapper>
                            </TableCell>
                            <TableCell
                                align="left"
                                className="title cell"
                                sx={{ width: '70%' }}
                            >
                                <ProductInfo>
                                    <StyledText className={row.isActive ? '' : 'inactive'}>
                                        <ProductTitle isCertificate={isCertificate} price={row.price} fullName={row.fullName} />
                                        {!isCertificate && <ProductProperties size={row.size} weight={row.weight} insert={row.insert} isActive={row.isActive} />}

                                    </StyledText>

                                    <StyledPrice>{`${row.price || ''} р.`}</StyledPrice>
                                </ProductInfo>
                            </TableCell>

                            <StyledCell
                                align="left"
                                className={`${
                                    row.isActive ? 'status-align active' : 'status-align inactive'
                                }`}
                            >
                                {row.isActive ? (
                                    <StyledDiv>
                                        <StyledStatusInfo />
                                        <span>В наличии</span>
                                    </StyledDiv>
                                ) : (
                                    <StyledDiv>
                                        <StyledStatusInfo />
                                        <span>Снят с продаж</span>
                                    </StyledDiv>
                                )}
                            </StyledCell>
                        </TableRow>
                    ))
                ) : (
                    <EmptyRow colSpan={5} />
                )}
            </TableBody>
        </Table>
    </TableContainer>
);
