import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as filtersApi from '../../../api/filters';

export const GET_ALL_FILTERS = 'catalog/getAllFilters';

export const getAllFilters = createAsyncThunk(
    GET_ALL_FILTERS,
    createRequest({
        type: GET_ALL_FILTERS,
        loader: async () => filtersApi.getAllFilters(),
    }),
);
