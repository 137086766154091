export const colors = {
    greyDark: '#393836',
    grey10: '#656565',
    grey10_40: '#65656566',
    grey20: '#939393',
    grey30: '#C7C7C7',
    grey40: '#E7E6E6',
    grey50: '#EDEDED',
    grey60: '#F7F7F7',
    grey70: '#F6F6F6',

    light: '#FDFDFD',

    green: '#6DB180',
    red: '#E74848',
    vine: '#BB3434',
    gold: '#D7B46A',
    brown: '#B89D65',
    blue: '#587EC6',

    white: '#FFFFFF',

    success: '#F0F7F2',
    error: '#FDEDED',
    info: '#FBF7F0',

    /* old colors */

    /*     gray: '#030A1A',
    gray40: '#030A1A66', */
    grayscale100: '#C0C0C0',
    grayscale80: '#2C2F33',
    grayscale60: '#DADADA',
    grayscale40: '#919599',
    grayscale20: '#D0D3D6',
    grayscale10: '#E9E9E9',
    grayscale05: '#F2F2F2',
    grayscale02: '#F7F7F7',
    grayscale01: '#F0F0F0',

    primary: '#163B99',
    primaryDark: '#0E2766',
    primaryDarkMode: '#5C83E5',
    primaryLight: '#F2F6FF',
    primaryBg: '#FAFBFF',

    secondary: '#E38024',
    secondaryDark: '#994B03',
    secondaryDarkMode: '#FFD5AD',
    secondaryLight: '#FFF2E5',
    secondaryBg: '#FFFCFA',
    secondaryBorder: '#C7C7C7',

    gradientPrimary: 'linear-gradient(137.15deg, #163B99 2.93%, #5C83E5 100%)',
    gradientSecondary: 'linear-gradient(137.15deg, #E38024 40%, #FFD5AD 100%)',

    // Attention Colors
    greenLight: '#E8FBE5',
    fail: '#FF82A8',
    redLight1: '#FEEFEF',
    warning: '#FFC97C',
    redStatusBackground: '#F414001A',
    redStatusText: '#F41400',

    green10: '#1ABC001A',
    gray10: '#9195991A',
    /*  blue: '#5C83E6', */
    blue10: '#5C83E51A',
    blue25: '#5C83E53F',
    yellow10: '#F4BE001A',
};
