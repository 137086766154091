import { PropertyValues } from '../../../types/Endpoints/filters/FiltersContracts';

export const checkBoxOperators = ['equals', 'not_equals'] as const;
export const rangeOperators = ['equals', 'not_equals', 'range', 'greater_than', 'less_than'] as const;

export type FilterCondition = {
  id: number;
  type: 'CHECK_BOX' | 'RADIO' | 'RANGE';
  name: string;
  operator: string;
  value: any;
  valueNumber: number | null | undefined;
  propertyValues: PropertyValues[];
};

export type FilterGroupType = {
  condition: 'AND' | 'OR';
  filters: (FilterCondition | FilterGroupType)[];
};
