import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Checkbox } from '../../../components/Checkbox';
import { BLOG_BLOCK_CONTENT_TYPES, BLOG_PATH, INIT_BLOG_DRAWER_STATE } from '../../../constants/Blog';
import { colors } from '../../../constants/Colors';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { routes } from '../../../constants/RouterPath';
import { PageTitle } from '../../../components/PageTitle';
import { UploadPhotoWithMiniature } from '../../../components/UploadPhotoWithMiniature';
import Switch from '../../../components/sw';
import { CopyToClipboard } from '../../../components/CopyToClipboard';
import { ProductBlocksType, ProductsType } from '../../../types/Endpoints/sales/PromitionsContracts';
import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../../components/Icon';
import { SubmitButtons } from '../../../components/StyledComponents';
import { UploadFile } from '../../../types/Endpoints/files/FilesContract';
import { AddProductsPromotions } from '../../../components/AddProductsToPromotions';
import { createBlogBreadcrumbs } from '../../../constants/BreadCrumbs/createBlogBreadcrumbs';
import { createBlogThunk, getTagsThunk } from '../../../actions/blog';
import {
    BlogBlockContentType, BlogContentBlockOrder,
    CreateBlogContract,
} from '../../../types/Endpoints/blog/BlogCRUDContracts';
import { DeleteButton } from '../../../components/Button/deleteButton';
import { useTypeSelector } from '../../../store';
import { selectTagsList } from '../../../selectors/blog';
import { GetTags } from '../../../types/Endpoints/blog/BlogContracts';
import { deleteTagTag } from '../../../api/blog';
import { ProductBlock } from './ProductBlock';
import { TextImageBlock } from './TextImageBlock';
import { addModal } from '../../../actions/modal';
import { getId } from '../../../helpers/generateId';
import { TypesModal } from '../../../types/TypesModalComponent';
import { isEqualObject } from '../../../helpers/isEqual';
import { addNotification } from '../../../actions/notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Textarea } from '../../../components/TextArea';
import * as S from '../styles';

export const CreateBlog = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const tags: GetTags['responseBody'] = useTypeSelector(selectTagsList);

    const [drawerState, setDrawerState] = useState(INIT_BLOG_DRAWER_STATE);
    const [selectedProducts, setSelectedProducts] = useState<ProductsType>([]);
    const [selectedTags, setSelectedTags] = useState<{ id: number }[]>([]);
    const [selectedProductsInModal, setSelectedProductsInModal] = useState<ProductsType>([]);
    const [selectedProductsByOrder, setSelectedProductsByOrder] = useState<ProductBlocksType<ProductsType>>([]);
    const [render, rerender] = useState<boolean>(false);
    const [contentOrder, setContentOrder] = useState<BlogContentBlockOrder>([]);

    const lastContentBlockId = contentOrder[contentOrder.length - 1]?.id;
    const newContentBlockId = lastContentBlockId ? lastContentBlockId + 1 : 1;

    const handleClickOpenModal = () => {
        dispatch(
            addModal({
                id: getId()(),
                type: TypesModal.CREATE_TAG,
                message: { description: 'Добавить тег' },
                onSuccessMessage: 'Добавить',
                onSuccess: () => {
                    rerender(prev => !prev);
                },
            }),
        );
    };

    const handleCheckTags = (newItemID: number) => {
        if (selectedTags.find(item => item.id === newItemID)) {
            setSelectedTags(selectedTags.filter(item => item.id !== newItemID));
        } else if (selectedTags.length < 3) {
            setSelectedTags([...selectedTags, { id: newItemID }]);
        }
    };

    const deleteTag = async (id: number) => {
        await deleteTagTag(id.toString());
        dispatch(getTagsThunk({}));
    };

    const addContentBlock = (type: BlogBlockContentType) => {
        setContentOrder(prev => {
            const copy = [...prev];
            const { length } = copy;

            copy.push({
                id: length ? copy[length - 1].id + 1 : 1,
                type,
            });

            return copy;
        });
    };

    const deleteContentBlock = (id?: number) => {
        setContentOrder(prev => {
            const copy = [...prev];

            if (!id) {
                copy.pop();
                return copy;
            }

            return copy.filter(item => item.id !== id);
        });
    };

    const deleteProductBlock = (id: number) => {
        deleteContentBlock(id);
        setSelectedProductsByOrder(prev => prev.filter(({ order }) => order !== id));
    };

    const deleteProductBlockGuard = (id: number) => {
        dispatch(
            addModal({
                id: getId()(),
                type: TypesModal.CONFIRM,
                message: {
                    description:
                    'Вы действительно хотите удалить?',
                },
                onSuccessMessage: 'Удалить',
                onSuccess: () => deleteProductBlock(id),
                withCloseIcon: true,
            }),
        );
    };

    const saveAndOffDrawer = (id?: number) => {
        if (!selectedProductsInModal.length) return;

        if (!id) {
            setSelectedProducts(selectedProductsInModal);
            setDrawerState(INIT_BLOG_DRAWER_STATE);

            return;
        }

        if (!contentOrder.find(item => item.id === id)) {
            addContentBlock(BLOG_BLOCK_CONTENT_TYPES.PRODUCT);
        }

        setSelectedProductsByOrder(prev => {
            const updatedItem = { order: id, products: selectedProductsInModal };

            if (!prev.length) {
                return [updatedItem];
            }

            if (!prev.find(item => item.order === id)) {
                return [...prev, updatedItem];
            }

            return prev.map(item => (item.order !== id ? item : updatedItem));
        });

        setDrawerState(INIT_BLOG_DRAWER_STATE);
    };

    const onDrawer = (id: number) => {
        setDrawerState({ id, isOpen: true });
        setSelectedProductsInModal(!id ? selectedProducts : selectedProductsByOrder?.find(item => item.order === id)?.products ?? []);
    };

    const offDrawer = () => {
        setDrawerState(INIT_BLOG_DRAWER_STATE);
        setSelectedProductsInModal([]);
    };

    const handleSubmit = (
        values: CreateBlogContract<UploadFile>['requestBody'],
    ) => {
        const prooducts = selectedProducts.map(item => ({ id: item.id }));
        const blogProductBlock = selectedProductsByOrder.map(item => ({
            ...item,
            products: item.products.map(p => ({ id: p.id })),
        }));

        const requestData: CreateBlogContract<number>['requestBody'] = {
            ...values,
            urn: values.urn.replace(BLOG_PATH, ''),
            seoH1: values.seoH1,
            seoTitle: values.seoTitle,
            seoDescription: values.seoDescription,
            headerImageFileId: values.headerImageFileId.id || 0,
            headerImageMobileFileId: values.headerImageMobileFileId.id || 0,
            publishDate: values.publishDate
                ? new Date(values.publishDate)
                : new Date(),
            bannerStatus: {
                status: values.bannerStatus.status,
                buttonText: values.bannerStatus.buttonText,
            },
            products: prooducts,
            blogProductBlock,
            tags: selectedTags,
            blogTextAndImageBlock: values.blogTextAndImageBlock.map(item => {
                const data: {
                    text: string;
                    order: number;
                    blogTextAndImageFileId?: number;
                } = {
                    text: item.text,
                    order: item.order,
                };
                if (item?.image?.id) {
                    data.blogTextAndImageFileId = item?.image?.id;
                }
                return data;
            }),
        };

        if (values.bannerStatus?.mobileImageFileId?.id) {
            requestData.bannerStatus.mobileImageFileId = values.bannerStatus.mobileImageFileId.id;
        }
        if (values.bannerStatus?.desktopImageFileId?.id) {
            requestData.bannerStatus.desktopImageFileId = values.bannerStatus.desktopImageFileId.id;
        }
        if (values.bannerStatus?.order) {
            requestData.bannerStatus.order = +values.bannerStatus.order;
        }

        dispatch(createBlogThunk(requestData));
    };

    const validationSchema = yup.object().shape({
        title: yup
            .string()
            .max(50, 'Не более 50 символов')
            .required('Поле является обязательным'),
        urn: yup
            .string()
            .test(
                'exception',
                'Поле является обязательным',
                value => !BLOG_PATH.includes(value || ''),
            ),
    });

    const form = useFormik<CreateBlogContract<UploadFile>['requestBody']>({
        onSubmit: handleSubmit,
        initialValues: {
            title: '',
            seoH1: '',
            seoTitle: '',
            seoDescription: '',
            urn: BLOG_PATH,
            publishDate: '',
            headerImageFileId: 0,
            headerImageMobileFileId: 0,
            bannerStatus: {
                status: 'INACTIVE',
                buttonText: 'Подробнее',
            },
            tags: [],
            blogTextAndImageBlock: [],
            products: [],
            blogProductBlock: [],
        } as CreateBlogContract<UploadFile>['requestBody'],
        validationSchema,
    });

    const handleToggle1 = (value: 'ACTIVE' | 'INACTIVE') => {
        form.values.bannerStatus.status = value;
        rerender(prev => !prev);
    };

    const addTextImageBlock = () => {
        addContentBlock(BLOG_BLOCK_CONTENT_TYPES.TEXT_AND_IMAGE);

        form.values.blogTextAndImageBlock.push({
            text: '',
            blogTextAndImageFileId: {},
            order: newContentBlockId,
        });

        rerender(prev => !prev);
    };

    const updateTextImageBlock = (
        text: string,
        image: UploadFile,
        order: number,
    ) => {
        form.setFormikState(prev => (
            {
                ...prev,
                values: {
                    ...prev.values,
                    blogTextAndImageBlock: prev.values.blogTextAndImageBlock.map(item => (item.order === order ? { ...item, text, image } : item)),
                },
            }
        ));
    };

    const deleteTextImageBlock = (id: number) => {
        const filtered = form.values.blogTextAndImageBlock.filter(({ order }) => order !== id);

        form.setFormikState(prev => (
            {
                ...prev,
                values: {
                    ...prev.values,
                    blogTextAndImageBlock: filtered,
                },
            }
        ));

        deleteContentBlock(id);
    };

    const handleResetForm = (
        values: CreateBlogContract<UploadFile>['requestBody'],
    ) => {
        history.push(routes.blog);
        const equal = isEqualObject(form.initialValues, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    useEffect(() => {
        dispatch(getTagsThunk({}));
    }, [render]);

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>Блог</PageTitle>
                <Breadcrumbs breadcrumbs={createBlogBreadcrumbs('Добавить')} />
                <S.StyledRoot>
                    <S.MainWrapper>
                        <S.IdInfoText>SEO</S.IdInfoText>
                        <Textarea
                            name="seoH1"
                            onChange={form.handleChange}
                            value={form.values.seoH1}
                            placeholder="H1"
                            maxLength={255}
                            title="H1"
                            isTouched={form.touched.seoH1}
                            error={form.errors.seoH1}
                        />
                        <Textarea
                            name="seoTitle"
                            onChange={form.handleChange}
                            value={form.values.seoTitle}
                            placeholder="Title"
                            maxLength={255}
                            title="Title"
                            isTouched={form.touched.seoTitle}
                            error={form.errors.seoTitle}
                        />
                        <Textarea
                            name="seoDescription"
                            onChange={form.handleChange}
                            value={form.values.seoDescription}
                            placeholder="Description"
                            maxLength={255}
                            title="Description"
                            isTouched={form.touched.seoDescription}
                            error={form.errors.seoDescription}
                        />
                    </S.MainWrapper>
                    <S.MainWrapper>
                        <S.IdInfoText>Основное</S.IdInfoText>
                        <S.StyledInput
                            name="title"
                            onChange={form.handleChange}
                            value={form.values.title}
                            placeholder="Заголовок (не более 50 символов)*"
                            typeInput="text"
                            maxLength={255}
                            title="Заголовок (не более 50 символов)*"
                            isTouched={form.touched.title}
                            error={form.errors.title}
                            count={form.submitCount}
                        />

                        <S.UrlWrapper>
                            <S.InputWithPlaceholder
                                name="urn"
                                onChange={form.handleChange}
                                value={form.values.urn.length < BLOG_PATH.length ? BLOG_PATH : form.values.urn}
                                typeInput="text"
                                title="URL*"
                                isTouched={form.touched.urn}
                                error={form.errors.urn}
                                count={form.submitCount}
                            />
                            <S.CopyWrapper>
                                <CopyToClipboard copyText={form.values.urn.length < BLOG_PATH.length ? BLOG_PATH : form.values.urn} />
                            </S.CopyWrapper>
                        </S.UrlWrapper>

                        <S.DateWrapper>
                            <S.StyledInput
                                name="publishDate"
                                onChange={form.handleChange}
                                value={
                                    typeof form.values.publishDate === 'string'
                                        ? form.values.publishDate
                                        : ''
                                }
                                placeholder="Дата публикации"
                                typeInput="date"
                                title="Дата публикации"
                                isTouched={form.touched.publishDate}
                                error={form.errors.publishDate}
                            />
                        </S.DateWrapper>
                    </S.MainWrapper>
                    <S.MainWrapper>
                        <S.Subtitle>Заглавное изображение</S.Subtitle>
                        <UploadPhotoWithMiniature
                            name="headerImageFileId"
                            title="Изображение для desktop версии сайта"
                            format="7:2.6:exc"
                            onChange={form.setFieldValue}
                            value={form.values.headerImageFileId}
                            maxSize={4}
                        />
                        <UploadPhotoWithMiniature
                            name="headerImageMobileFileId"
                            title="Изображение для мобильной версии сайта"
                            format="3.5:2:exc"
                            onChange={form.setFieldValue}
                            value={form.values.headerImageMobileFileId}
                            maxSize={3}
                        />
                    </S.MainWrapper>

                    <S.TagsWrapper>
                        <S.Subtitle>Статус отображения в баннере</S.Subtitle>
                        <Switch
                            name="isShowOnBanner"
                            value={form.values.bannerStatus.status === 'ACTIVE'}
                            onChange={() => handleToggle1(
                                form.values.bannerStatus.status === 'ACTIVE'
                                    ? 'INACTIVE'
                                    : 'ACTIVE',
                            )}
                            type="checkbox"
                            title
                            inactiveTitle="Выключено"
                            activeTitle="Активный"
                        />

                        {form.values.bannerStatus.status === 'ACTIVE' && (
                            <>
                                <S.StyledInput
                                    name="bannerStatus.buttonText"
                                    onChange={form.handleChange}
                                    value={form.values.bannerStatus.buttonText}
                                    placeholder="Текст кнопки"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Текст кнопки"
                                    isTouched={form?.touched?.bannerStatus?.buttonText}
                                    error={form?.errors?.bannerStatus?.buttonText}
                                />
                                <S.InputWrapper>
                                    <S.StyledInput
                                        name="bannerStatus.order"
                                        onChange={form.handleChange}
                                        value={form.values.bannerStatus.order}
                                        placeholder="Сортировка"
                                        typeInput="text"
                                        maxLength={255}
                                        title="Сортировка"
                                        isTouched={form?.touched?.bannerStatus?.order}
                                        error={form?.errors?.bannerStatus?.order}
                                    />
                                </S.InputWrapper>

                                <UploadPhotoWithMiniature
                                    name="bannerStatus.desktopImageFileId"
                                    title="Изображение для desktop версии"
                                    format="9.5:4"
                                    onChange={form.setFieldValue}
                                    value={form.values.bannerStatus.desktopImageFileId}
                                />
                                <UploadPhotoWithMiniature
                                    name="bannerStatus.mobileImageFileId"
                                    title="Изображение для мобильной версии"
                                    format="2:1"
                                    onChange={form.setFieldValue}
                                    value={form.values.bannerStatus.mobileImageFileId}
                                />
                            </>
                        )}
                    </S.TagsWrapper>

                    <S.TagsWrapper>
                        <S.SubtitleWithDescription>
                            <S.Subtitle>Теги</S.Subtitle>

                            <S.CountInfo>Максимально 3 тега</S.CountInfo>
                        </S.SubtitleWithDescription>

                        <S.TagsWrapper>
                            {tags
                                && tags.map(item => (
                                    <S.FlexRow key={item.id}>
                                        <Checkbox
                                            name="checked"
                                            checked={
                                                !!selectedTags.find(
                                                    selectedItem => item.id === selectedItem.id,
                                                )
                                            }
                                            onChange={() => handleCheckTags(item.id)}
                                        />

                                        <S.TagName>{item.title}</S.TagName>

                                        <DeleteButton
                                            typeButton={TypesButton.SECONDARY}
                                            size={SizesButton.M}
                                            onClick={() => {
                                                deleteTag(item.id);
                                            }}
                                        />
                                    </S.FlexRow>
                                ))}
                        </S.TagsWrapper>

                        <S.AddButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={handleClickOpenModal}
                        >
                            <Icon
                                type={TypesIcon.PLUS_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                color={colors.greyDark}
                            />
                            Добавить тег
                        </S.AddButton>
                    </S.TagsWrapper>

                    <S.CheckboxWrapper>
                        {contentOrder.map(({ id, type }) => {
                            if (type === BLOG_BLOCK_CONTENT_TYPES.TEXT_AND_IMAGE) {
                                const block = form.values.blogTextAndImageBlock.find(i => i.order === id);

                                if (!block) return null;

                                return (
                                    <TextImageBlock
                                        key={id}
                                        item={block}
                                        updateTextImageBlock={updateTextImageBlock}
                                        deleteTextImageBlock={deleteTextImageBlock}
                                    />
                                );
                            }

                            const length = selectedProductsByOrder.find(({ order }) => order === id)?.products?.length ?? 0;

                            return (
                                <ProductBlock key={id} id={id} length={length} onEdit={onDrawer} onDelete={deleteProductBlockGuard} />
                            );
                        })}

                        <S.AddButtonInline onClick={addTextImageBlock}>
                            <Icon
                                type={TypesIcon.PLUS_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                color={colors.greyDark}
                            />
                            Добавить текст и изображение
                        </S.AddButtonInline>
                        <S.AddButtonInline onClick={() => onDrawer(newContentBlockId)}>
                            <Icon
                                type={TypesIcon.PLUS_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                color={colors.greyDark}
                            />
                            Добавить товары вручную
                        </S.AddButtonInline>
                    </S.CheckboxWrapper>

                    <S.AddButtonWrapper>
                        <S.AddButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={() => onDrawer(0)}
                        >
                            <Icon
                                type={TypesIcon.PLUS_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                color={colors.greyDark}
                            />
                            Добавить товары
                        </S.AddButton>
                        <S.CountInfo>{`Добавлено ${selectedProducts.length} товаров`}</S.CountInfo>
                    </S.AddButtonWrapper>
                </S.StyledRoot>

                <S.StyledDrawer anchor="right" open={drawerState.isOpen} onClose={offDrawer}>
                    <S.DrawerRoot>
                        <S.Heading>
                            <S.ModalTitle>Добавить товары</S.ModalTitle>
                            <S.CloseButton onClick={offDrawer}>
                                <Icon
                                    type={TypesIcon.CLOSE_BIG}
                                    color={colors.grey20}
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                />
                            </S.CloseButton>
                        </S.Heading>
                        <S.ModalDescription>{`Добавлено ${selectedProductsInModal.length} товаров в блог`}</S.ModalDescription>
                        <AddProductsPromotions
                            selectedProducts={selectedProductsInModal}
                            setSelectedProducts={setSelectedProductsInModal}
                        />

                        <S.ButtonWrapper>
                            <S.StyledButton
                                typeButton={TypesButton.SECONDARY}
                                size={SizesButton.M}
                                onClick={offDrawer}
                            >
                                Отменить
                            </S.StyledButton>
                            <S.StyledButton
                                typeButton={TypesButton.PRIMARY}
                                size={SizesButton.M}
                                onClick={() => saveAndOffDrawer(drawerState.id)}
                            >
                                Сохранить изменения
                            </S.StyledButton>
                        </S.ButtonWrapper>
                    </S.DrawerRoot>
                </S.StyledDrawer>
            </S.Wrapper>
            <SubmitButtons
                reset={() => handleResetForm(form.values)}
                submit={form.submitForm}
            />
        </S.RootWrapper>
    );
};
