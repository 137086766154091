import { SyntheticEvent, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { Checkbox } from '../../../../components/Checkbox';
import { Input } from '../../../../components/Input';
import { colors } from '../../../../constants/Colors';
import { typography } from '../../../../helpers/styles/typography';
import { units } from '../../../../helpers/styles/units';
import { FontWeight } from '../../../../constants/Styles/fontWeight';
import { Button } from '../../../../components/Button';
import { useTypeSelector } from '../../../../store';
import { TypesButton } from '../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../constants/SizeButton';
import { routes } from '../../../../constants/RouterPath';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import { PageTitle } from '../../../../components/PageTitle';
import { selectFilter, selectGetFilterLoader } from '../../../../selectors/filters';
import { selectProperties } from '../../../../selectors/properties';
import { FilterTypes } from '../../../../types/Endpoints/filters/FiltersContracts';
import { filtersBreadcrumbs } from '../../../../constants/BreadCrumbs/filtersBreadcrumbs';
import { SelectOption } from '../../../../types/Select/SelectOption';
import { TypesSelect } from '../../../../types/TypesSelect';
import { RadioGroup } from '../../../../components/RadioButtonGroup';
import { Select } from '../../../../components/Select';
import { RadioOption } from '../../../../types/form/RadioOption';
import { selectCategories } from '../../../../selectors/catalog';
import { Icon } from '../../../../components/Icon';
import { TypesIcon } from '../../../../types/TypesIcon';
import { deleteFilter, editFilter } from '../../../../actions/filters';
import { ButtonRoot } from '../../../../components/StyledComponents';
import { isEqualObject } from '../../../../helpers/isEqual';
import { addNotification } from '../../../../actions/notifications';
import { TypesSnackbars } from '../../../../types/TypesSnackbars';
import { addModal } from '../../../../actions/modal/addModal';
import { getId } from '../../../../helpers/generateId';
import { TypesModal } from '../../../../types/TypesModalComponent';

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

const StyledTitleInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledSelect = styled(Select)`
  background-color: ${colors.light};

  & .label {
    color: ${colors.greyDark};
  }

  :not(:last-child) {
    margin-bottom: ${units(3)};
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${units(8)};

  padding: ${units(12)};

  max-width: ${units(322)};
`;

const StyledDeleteButton = styled(StyledButton)`
  width: fit-content;
`;

const OrderWrapper = styled.div`
  width: ${units(44)};
`;

const typeControls: RadioOption[] = [
    {
        value: FilterTypes.RADIO,
        title: 'RadioButton',
    },
    {
        value: FilterTypes.CHECK_BOX,
        title: 'Checkbox',
    },
    {
        value: FilterTypes.RANGE,
        title: 'Диапазон',
    },
];

interface EditFilterModel {
    propertyId?: number;
    name?: string;
    urn?: string;
    order?: number;
    type: FilterTypes;
    isShowOnSite?: boolean;
    isShowOnMobile?: boolean;
    filterCategories?: {
        categoryId: number;
    }[];
}

const EditFilterContent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedFilter = useTypeSelector(selectFilter);
    const properties = useTypeSelector(selectProperties);
    const categories = useTypeSelector(selectCategories);
    const propertiesOptions = properties?.map(property => ({
        id: property.id,
        name: property.code,
    })).sort((a, b) => a.name.localeCompare(b.name)) || [];

    const handleSubmit = (values: EditFilterModel) => {
        const requestData: EditFilterModel = {
            name: values.name,
            urn: values.urn,
            isShowOnSite: values.isShowOnSite,
            isShowOnMobile: values.isShowOnMobile,
            propertyId: values.propertyId,
            type: values.type,
            filterCategories: values.filterCategories,
            order: Number(values?.order) || 0,
        };

        // eslint-disable-next-line no-unused-expressions
        selectedFilter && dispatch(editFilter({ requestData, filterId: selectedFilter.id.toString() }));
    };

    const form = useFormik<EditFilterModel>({
        onSubmit: handleSubmit,
        initialValues: {
            name: selectedFilter?.name,
            urn: selectedFilter?.urn,
            order: selectedFilter?.order || 0,
            isShowOnSite: selectedFilter?.isShowOnSite,
            isShowOnMobile: selectedFilter?.isShowOnMobile,
            propertyId: selectedFilter?.propertyId,
            type: selectedFilter?.type,
            filterCategories: [],
        } as EditFilterModel,
    });

    useEffect(() => {
        const currentProperty = properties?.find(item => item.id === form.values.propertyId);
        form.setFieldValue('urn', currentProperty ? currentProperty.code + (Math.random() * 1000).toFixed().toString() : '');
    }, [form.values.propertyId]);

    useEffect(() => {
        const newFilterCategories = selectedFilter?.filterCategories.map(item => ({ categoryId: item.id }));
        form.setFieldValue('filterCategories', newFilterCategories);
    }, []);

    const handleChangeOrder = (value: number) => {
        const regex = /^[0-9\b]+$/;
        if (regex.test(value.toString())) {
            form.setFieldValue('order', value);
        }
    };

    const handleResetForm = (values: EditFilterModel) => {
        history.push(routes.filters);

        const initData = {
            name: selectedFilter?.name,
            urn: selectedFilter?.urn,
            isShowOnSite: selectedFilter?.isShowOnSite,
            isShowOnMobile: selectedFilter?.isShowOnMobile,
            propertyId: selectedFilter?.propertyId,
            type: selectedFilter?.type,
            filterCategories: [],
            order: selectedFilter?.order,
        } as EditFilterModel;

        const equal = isEqualObject(initData, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Фильтры</PageTitle>
                {selectedFilter && <Breadcrumbs breadcrumbs={filtersBreadcrumbs(selectedFilter.name)} />}
                <StyledRoot>
                    <StyledMainWrapper>
                        <StyledInput
                            name="name"
                            onChange={form.handleChange}
                            value={form.values.name}
                            placeholder="Название в системе"
                            typeInput="text"
                            maxLength={255}
                            title="Название в системе"
                            isTouched={form.touched.name}
                            error={form.errors.name}
                        />
                        <StyledSelect
                            name="propertyId"
                            options={propertiesOptions as unknown as SelectOption[]}
                            selectType={TypesSelect.DEFAULT}
                            value={form.values.propertyId}
                            label="Элемент из выгрузки"
                            onClick={form.setFieldValue}
                            title="Элемент из выгрузки"
                            isTouched={form.touched.propertyId}
                            error={form.errors.propertyId}
                        />
                        <StyledInput
                            name="urn"
                            onChange={form.handleChange}
                            value={form.values.urn}
                            placeholder="URN"
                            typeInput="text"
                            maxLength={255}
                            title="URN"
                            isTouched={form.touched.urn}
                            error={form.errors.urn}
                        />

                        <OrderWrapper>
                            <StyledInput
                                name="order"
                                onChange={e => {
                                    handleChangeOrder(Number(e.target.value));
                                }}
                                value={form.values.order}
                                placeholder="Сортировка"
                                typeInput="text"
                                maxLength={10}
                                title="Сортировка"
                                isTouched={form.touched.order}
                                error={form.errors.order}
                            />
                        </OrderWrapper>

                    </StyledMainWrapper>
                    <StyledMainWrapper>
                        <StyledTitleInfo>Категории</StyledTitleInfo>

                        {categories?.map(item => {
                            const prevState = form.values.filterCategories ? form.values.filterCategories : [];
                            const isChecked = form.values.filterCategories ? !!form.values.filterCategories?.find(value => +item.id === +value.categoryId) : false;
                            return (
                                <Checkbox
                                    key={item.id}
                                    name="filterCategories"
                                    id={item.id.toString()}
                                    title={item.name}
                                    checked={isChecked}
                                    onChange={(event: SyntheticEvent) => {
                                        const newState = prevState.filter(value => +item.id !== +value.categoryId);
                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                        form.setFieldValue('filterCategories', newState.length === prevState.length ? [...prevState, { categoryId: +event.currentTarget?.id }] : newState);
                                    }}
                                />
                            );
                        })}
                    </StyledMainWrapper>
                    <RadioGroup
                        name="type"
                        controls={typeControls}
                        onChange={form.setFieldValue}
                        fieldValue={form.values.type}
                        blockTitle="Способ фильтрации"
                    />
                    <StyledMainWrapper>
                        <StyledTitleInfo>Отображение</StyledTitleInfo>
                        <Checkbox
                            name="isShowOnSite"
                            title="Отображение на сайте"
                            checked={form.values.isShowOnSite}
                            onChange={form.handleChange}
                        />

                        <Checkbox
                            name="isShowOnMobile"
                            title="Отображение в мобильном приложении"
                            checked={form.values.isShowOnMobile}
                            onChange={form.handleChange}
                        />
                    </StyledMainWrapper>
                    <StyledDeleteButton
                        onClick={() => {
                            dispatch(
                                addModal({
                                    id: getId()(),
                                    type: TypesModal.CONFIRM,
                                    message: {
                                        description:
                                          'Вы действительно хотите удалить?',
                                    },
                                    onSuccessMessage: 'Удалить',
                                    onSuccess: () => selectedFilter && dispatch(deleteFilter(selectedFilter.id)),
                                    withCloseIcon: true,
                                }),
                            );
                        }}
                        typeButton={TypesButton.DELETE}
                        size={SizesButton.M}
                    >
                        <Icon
                            type={TypesIcon.TRASH_BIG}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                        />
                        Удалить
                    </StyledDeleteButton>
                </StyledRoot>
            </StyledWrapper>
            <ButtonRoot>
                <ButtonWrapper>
                    <StyledButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={() => handleResetForm(form.values)}
                    >
                        Отменить
                    </StyledButton>
                    <StyledButton
                        typeButton={TypesButton.PRIMARY}
                        size={SizesButton.M}
                        onClick={form.submitForm}
                    >
                        Сохранить изменения
                    </StyledButton>
                </ButtonWrapper>
            </ButtonRoot>
        </StyledRootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetFilterLoader,
});

export const EditFilterWithLoader = loader(EditFilterContent);
