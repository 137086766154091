import { FormatsService } from '../../../types/DinamikPages';
import { PaymentAndDeliveryChasi } from './PaymentAndDeliveryChasi';
import { PaymentAndDeliveryYuv } from './PaymentAndDeliveryYuv';

export const TAB_DATA = [
    { name: 'Наручные часы и ручки', content: <PaymentAndDeliveryChasi /> },
    { name: 'Ювелирные изделия', content: <PaymentAndDeliveryYuv /> },
];

export const FORMATS_DELIVERY: FormatsService = [
    {
        format: '4:3',
        index: 1,
    },
    {
        format: '2:3',
        index: 20,
    },
];
