import { FC } from 'react';
import { DeleteButton } from '../../../components/Button/deleteButton';
import { EditButton } from '../../../components/Button/editButton';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import * as S from '../styles';

interface Props {
  id: number
  length: number;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
}

export const ProductBlock: FC<Props> = ({
    id, length, onEdit, onDelete,
}) => (
    <S.CountInfoWithControls>
        {`Добавлено ${length} товаров`}
        <EditButton
            typeButton={TypesButton.SECONDARY}
            size={SizesButton.M}
            onClick={() => onEdit(id)}
        />
        <DeleteButton
            typeButton={TypesButton.SECONDARY}
            size={SizesButton.M}
            onClick={() => onDelete(id)}
        />
    </S.CountInfoWithControls>
);
