import { config } from '../../config';

export const BLOG_PATH = `${config.frontStageUrl}blog/`;

export const BLOG_BLOCK_CONTENT_TYPES = {
    PRODUCT: 'product',
    TEXT_AND_IMAGE: 'textAndImage',
} as const;

export const INIT_BLOG_DRAWER_STATE = { id: 0, isOpen: false };
