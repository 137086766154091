import { EndpointContract } from '../EndpointContract';

interface GoodsImage {
  pictureURL: string
}

export interface Goods {
  id: string;
  sku: string;
  size: string;
  insert: string;
  weight: string;
  quantity: string;
  name: string;
  fullName: string;
  isActive: boolean;
  pictures?: GoodsImage[];
  price: number;
}

export enum OrderPlatform {
  MOBILE_APP = 'MOBILE_APP',
  DESKTOP_WEBSITE = 'DESKTOP_WEBSITE',
  MOBILE_WEBSITE = 'MOBILE_WEBSITE',
}

export enum PaymentStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export enum OrderTypes {
  PRODUCT = 'PRODUCT',
  CERTIFICATE = 'CERTIFICATE',
}

export type OrderType = OrderTypes.PRODUCT | OrderTypes.CERTIFICATE;

export interface OrderItemAddress {
  id?: number,
  userId?: number,
  address: string,
  apartment: string,
  entrance: string,
  doorCode: string,
  floor: string,
  postalCode: number,
  latitude: number,
  longitude: number,
}

export interface Order {
  id: number;
  zikoOrderId: string;
  userId: number;
  client: string;
  clientPhone: string;
  clientEmail: string;
  comment: string;
  delivery: string;
  payment: string;
  paymentStatus?: PaymentStatus;
  platform: OrderPlatform;
  storeId: number;
  addressId: null;
  address: string;
  promocode: null;
  status: string;
  goods: Goods[];
  createdAt: string;
  confirmedAt: string;
  date: string;
  paid: boolean;
  totalSumOrig: string;
  totalSum: string;
  totalDiscount: string;
  type: OrderType;
  userAddress?: OrderItemAddress;
}

export type OrderStatusType =
  | 'NEW'
  | 'PROCESSING'
  | 'IN_PROGRESS'
  | 'READY'
  | 'COMPLETED'
  | 'REFUSED'
  | 'DELIVERING';

export interface OrdersContract extends EndpointContract {
  responseBody: Order[];
  pathParams: {
    dateFrom?: string;
    dateTo?: string;
    search?: string;
    statuses?: string[];
  };
}

export interface OrderContract extends EndpointContract {
  responseBody: Order;
}

export interface GetOrdersStatusContract extends EndpointContract {
  responseBody: string[]
}
