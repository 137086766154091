export const config = {
    nodeEnv: process.env.NODE_ENV,
    baseUrl: process.env.REACT_APP_API_URL || 'http://86.57.148.239:52080/v1',
    imageUrl: process.env.REACT_APP_IMAGE_URL || 'http://86.57.148.239:52080/uploads',
    // baseUrl: process.env.REACT_APP_API_URL || 'https://dev1.ziko.by/v1',
    // imageUrl: process.env.REACT_APP_IMAGE_URL || 'https://dev1.ziko.by/uploads',
    // baseUrl: process.env.REACT_APP_API_URL || 'http://localhost:5001/v1',
    // imageUrl: process.env.REACT_APP_IMAGE_URL || 'http://localhost:5001/uploads',

    imageUrlProd: process.env.REACT_APP_PROD_URL || 'http://86.57.148.239:52080/',
    mapKey: process.env.REACT_APP_YMAP_KEY || '43311efb-db3a-452e-abbf-37c965b51040',
    geoMapKey: process.env.REACT_APP_YMAP_GEO_KEY || '4221122d-2b20-4cdb-947a-3edcf2b2ac4c',
    frontStageUrl: process.env.REACT_APP_PROD_URL || 'http://86.57.148.239:52443/',
};
