import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_FILTER, GET_FILTERS, GET_ALL_FILTERS } from '../../actions/filters';

const selectRoot = ({ catalog }: RootState) => catalog;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetFilterLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_FILTER],
);

export const selectGetFiltersLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_FILTERS],
);

export const selectAllFilters = createSelector(
    selectRoot,
    ({ allFilters }) => allFilters,
);

export const selectFilters = createSelector(
    selectRoot,
    ({ filters }) => filters,
);

export const selectFilter = createSelector(
    selectRoot,
    ({ filter }) => filter,
);
