import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { Input } from '../../../components/Input';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { units } from '../../../helpers/styles/units';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { Button } from '../../../components/Button';
import { TextEditor } from '../../../components/TextEditor';

export const RootWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};
  padding-top: ${units(20)};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

export const CheckboxWrapper = styled(MainWrapper)`
  gap: ${units(12)};
`;

export const IdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

export const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};

  & ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const NumberInputWrapper = styled.div`
  width: ${units(83)};
`;

export const Subtitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  ${typography(10)};
`;

export const StyledTextEditor = styled(TextEditor)`
  margin-bottom: 0;
  
  & > p {
    margin-bottom: ${units(8)};

    font-weight: ${FontWeight.MEDIUM};
  }

  & .ql-toolbar.ql-snow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: ${units(28)};
    padding: 6px 16px;

    border-radius: 0px;
    border-color: #c7c7c7;
  }

  & .ql-snow .ql-editor h3 {
    text-align: center;
  }

  & .ql-container.ql-snow {
    border-radius: 0px;
    border-color: #c7c7c7;

    & .ql-editor {
      min-height: ${units(28)};
      padding: 16px 24px;
    }
  }
`;

export const UrlWrapper = styled.div`
  position: relative;
`;

export const CopyWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 10;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(16)};
`;

export const AddButton = styled(StyledButton)`
  width: fit-content;
  display: flex;
  align-items: center;

  gap: ${units(8)};

  font-weight: ${FontWeight.BOLD};
`;

export const CountInfo = styled.div`
  color: ${colors.grey20};

  ${typography(10)};
`;

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    width: ${units(337)};
    padding: ${units(27)};
    box-shadow: none;
  }

  & .MuiBackdrop-root.MuiModal-backdrop {
    background: ${colors.grey10};
    opacity: 0.4 !important;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${units(8)};
`;

export const ModalTitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 1px;

  ${typography(20)};
`;

export const ModalDescription = styled.div`
  letter-spacing: 1px;
  color: #939393;
  margin-top: ${units(8)};
  ${typography(14)};
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${units(8)};
  padding: ${units(12)} ${units(27)};
  width: ${units(324)};

  background-color: ${colors.light};
  border-top: 1px solid ${colors.grey40};

  position: fixed;
  bottom: 0;
  right: 25px;
  z-index: 1000;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
      hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
      hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

export const DrawerRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWithPlaceholder = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  ::placeholder {
    top: 31px;
  }

  background-color: ${colors.light};

  ${typography(10)};

  & ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
`;

export const Placeholder = styled.span`
  position: absolute;
  ${typography(10)};
  color: #393836;
  top: 27px;
  z-index: 2;
`;

export const FlexWrapper = styled(MainWrapper)`
  gap: ${units(8)};
`;

export const Dicription = styled(Subtitle)`
  font-weight: ${FontWeight.REGULAR};

  color: ${colors.grey20};
`;
