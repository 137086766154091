/* eslint-disable no-unsafe-optional-chaining */

import styled from 'styled-components';
import {
    Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { units } from '../../helpers/styles/units';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { GetPromotionContract, ProductItemType } from '../../types/Endpoints/sales/PromitionsContracts';
import { Checkbox } from '../Checkbox';
import { config } from '../../config';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Button } from '../Button';
import { SearchBar } from '../SearchBar';
import { FilterButton } from '../Button/filterButton';
import { selectCategories } from '../../selectors/catalog';
import { useTypeSelector } from '../../store';
import DefaultImage from '../../styles/images/products/default.png';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(8)};
  padding: ${units(27)} ${units(27)} ${units(75)} ${units(27)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${units(324)};
  position: relative;
`;

const StyledHeading = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: ${units(8)};
  padding: ${units(27)};
  padding-bottom: ${units(0)};
`;

const StyledSearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

const StyledFilterWrapper = styled.div`
  position: relative;
`;

const StyledModalTitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 1px;

  ${typography(20)};
`;

const StyledCloseButton = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
      hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
      hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

const StyledClose = styled(StyledCloseButton)`
  margin-left: auto;
`;

const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(8)};
`;

const StyledImage = styled.img`
  width: ${units(24)};
  height: ${units(24)};
  object-fit: contain;
`;

const StyledText = styled.div`
  letter-spacing: 0.7px;

  ${typography(5)};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${units(8)};
  padding: ${units(12)} ${units(27)};
  width: ${units(324)};

  background-color: ${colors.light};
  border-top: 1px solid ${colors.grey40};

  position: fixed;
  bottom: 0;
  right: 25px;
  z-index: 1000;
`;

const StyledFilterWindow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(16)};
  position: absolute;
  right: 0;
  top: ${units(32)};
  box-shadow: 0px 0px 20px 0px rgba(57, 56, 54, 0.2) !important;
  border-radius: 0;
  background-color: #f7f7f7;
  padding: 32px 40px;
  z-index: 9999;
  overflow-y: scroll;
`;

const StyledCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: ${units(125)};
`;

const StyledIdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};

  ${typography(10)};
`;

const StyledButtonClear = styled(Button)`
  width: fit-content;
  height: fit-content;
  padding: 0;
`;

export const AllProductsList = ({
    onClose,
    saveAndClose,
    allProducts,
    loadMore,
    selectedCategories,
    setSelectedCategories,
    setSelectedProducts,
    selectedProducts,
}: {
    onClose: () => void,
    saveAndClose: () => void,
    loadMore: () => void,
    selectedCategories: number[],
    setSelectedCategories: (newList: number[]) => void,
    allProducts: ProductItemType[] | undefined,
    setSelectedProducts: Dispatch<SetStateAction<GetPromotionContract['responseBody']['products']>>,
    selectedProducts: GetPromotionContract['responseBody']['products'],
}) => {
    const categories = useTypeSelector(selectCategories);
    const [checkedProducts, setCheckedProducts] = useState(selectedProducts);

    const saveHandler = () => {
        saveAndClose();
        setSelectedProducts(checkedProducts);
    };

    const handleCheckCategories = (id: number) => {
        if (selectedCategories.includes(id)) {
            setSelectedCategories(selectedCategories.filter(item => item !== id));
        } else {
            setSelectedCategories([...selectedCategories, id]);
        }
    };

    const handleCheckProducts = (newItem: ProductItemType) => {
        if (checkedProducts.find(item => item.id === newItem.id)) {
            setCheckedProducts(checkedProducts.filter(item => item.id !== newItem.id));
        } else {
            setCheckedProducts([...checkedProducts, newItem]);
        }
    };

    const selectAllCategories = () => {
    // eslint-disable-next-line no-unused-expressions
        categories?.length
      && setSelectedCategories(categories.map(item => item.id));
    };

    useEffect(() => {
        selectAllCategories();
    }, [categories]);

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    return (
        <StyledWrapper>
            <StyledHeading>
                <StyledCloseButton onClick={onClose}>
                    <Icon
                        type={TypesIcon.ARROW_LEFT_BIG}
                        color={colors.grey20}
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                    />
                </StyledCloseButton>
                <StyledModalTitle>Загрузить товары из каталога</StyledModalTitle>
                <StyledClose onClick={onClose}>
                    <Icon
                        type={TypesIcon.CLOSE_BIG}
                        color={colors.grey20}
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                    />
                </StyledClose>
            </StyledHeading>
            <StyledHeading>
                <StyledSearchBarWrapper>
                    <SearchBar />
                </StyledSearchBarWrapper>

                <StyledFilterWrapper>
                    <FilterButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={() => setIsFilterOpen(prev => !prev)}
                    />
                    {isFilterOpen && (
                        <StyledFilterWindow>
                            <StyledCheckBoxWrapper>
                                <StyledIdInfo>Категория</StyledIdInfo>
                                {categories && categories.map(item => (
                                    <Checkbox
                                        key={item.id}
                                        name="isShowOnSite"
                                        title={item.name}
                                        checked={selectedCategories.includes(item.id)}
                                        onChange={() => handleCheckCategories(item.id)}
                                    />
                                ))}
                            </StyledCheckBoxWrapper>

                            <StyledButtonClear
                                onClick={() => setSelectedCategories([])}
                                typeButton={TypesButton.TEXT}
                            >
                                Очистить фильтр
                            </StyledButtonClear>
                        </StyledFilterWindow>
                    )}
                </StyledFilterWrapper>
            </StyledHeading>
            <StyledRoot>
                {allProducts && allProducts.map(item => (
                    <StyledFlexRow key={item.id}>
                        <Checkbox
                            name="checked"
                            checked={!!checkedProducts.find(selectedItem => item.id === selectedItem.id)}
                            onChange={() => handleCheckProducts(item)}
                        />

                        {item?.pictures?.length ? (
                            <StyledImage
                                src={`${config.imageUrlProd}${item.pictures[0].pictureURL}`}
                                alt=""
                            />
                        ) : (
                            <StyledImage src={`${DefaultImage}`} alt="" />
                        )}
                        <StyledText>{item.fullName}</StyledText>
                    </StyledFlexRow>
                ))}
                <StyledButton
                    typeButton={TypesButton.SECONDARY}
                    size={SizesButton.M}
                    onClick={loadMore}
                >
                    Показать еще
                </StyledButton>
            </StyledRoot>

            <ButtonWrapper>
                <StyledButton
                    typeButton={TypesButton.SECONDARY}
                    size={SizesButton.M}
                    onClick={onClose}
                >
                    Отменить
                </StyledButton>
                <StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.M}
                    onClick={saveHandler}
                >
                    Сохранить изменения
                </StyledButton>
            </ButtonWrapper>
        </StyledWrapper>
    );
};
