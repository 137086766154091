export enum TypesIcon {
    SEARCH = 'search',
    MENU = 'menu',
    NOTIFICATION = 'notification',
    MAP = 'map',
    COPY = 'copy',
    EDIT = 'edit',
    EDIT_BIG = 'editBig',
    ROBOTS = 'robots',
    PROFILE = 'profile',
    SMS = 'sms',
    NOTIFICATION_STATUS = 'notificationStatus',
    DOWNLOAD_MAIL = 'downloadMail',
    CLIPBOARD_EXPORT = 'clipboardExport',
    FILTER_LINE = 'filterLine',
    FILTER = 'filter',
    FILTER_BIG = 'filterBig',
    ROWS = 'rows',
    STAR = 'star',
    CATEGORY = 'category',
    CATEGORY_BIG = 'categoryBig',
    LAMP_ON = 'lampOn',
    CLIPBOARD_TICK = 'clipboardTick',
    GIFT = 'gift',
    CALENDAR = 'calendar',
    USER = 'user',
    NOTE_ADD = 'noteAdd',
    NOTE = 'note',
    MAXIMIZE = 'maximize',
    MESSAGES = 'messages',
    TRASH = 'trash',
    TRASH_BIG = 'trashBig',
    IMAGE = 'img',
    ARROW_DOWN = 'arrowDown',
    ARROW_UP = 'arrowUp',
    ARROW_LEFT = 'arrowLeft',
    ARROW_RIGHT = 'arrowRight',
    ARROW_DOWN_BIG = 'arrowDownBig',
    ARROW_UP_BIG = 'arrowUpBig',
    ARROW_LEFT_BIG = 'arrowLeftBig',
    ARROW_RIGHT_BIG = 'arrowRightBig',
    ARROW_SMALL = 'arrowSmall',
    CLOSE = 'close',
    CLOSE_BIG = 'closeBig',
    PLUS = 'plus',
    MINUS = 'minus',
    CHECK = 'check',
    UPLOAD = 'upload',
    ERROR_APP = 'errorApp',
    FULL_SCREEN = 'fullScreen',
    LIST_DOT = 'listDot',
    MENU_STAR = 'menuStar',
    MENU_REVIEW = 'menuReview',
    MENU_LEARNING = 'menuLearning',
    MENU_VOCATION = 'menuVocation',
    MENU_COMPLAINTS = 'menuComplaints',
    MENU_PERSONAL = 'menuPersonal',
    MENU_BEST_EMPLOYEES = 'menuBestEmployees',
    MENU_NEWS = 'menuNews',
    MENU_MAP = 'menuMap',
    MENU_SHOP = 'menuShop',
    MENU_CALENDAR = 'menuCalendar',
    MENU_DIRECTOR = 'menuDirector',
    MENU_CATEGORY = 'menuCategory',
    HORIZONTAL_MENU = 'horizontalMenu',
    FOLDER_BIG = 'folderBig',
    FOLDER = 'folder',
    PLANE_BIG = 'planeBig',
    PLANE = 'plane',
    PEOPLE_BIG = 'peopleBig',
    PEOPLE = 'people',
    TEACHER_BIG = 'teacherBig',
    TEACHER = 'teacher',
    MESSAGE_EDIT_BIG = 'messageEditBig',
    MESSAGE_EDIT = 'messageEdit',
    BOOK_BIG = 'bookBig',
    BOOK = 'book',
    CLIPBOARD_IMPORT_BIG = 'clipboardImportBig',
    CLIPBOARD_IMPORT = 'clipboardImport',
    DOCUMENT_TEXT_BIG = 'documentTextBig',
    DOCUMENT_TEXT = 'documentText',
    EMOJI_SAD_BIG = 'emojiSadBig',
    EMOJI_SAD = 'emojiSad',
    BOOK_SAVED_BIG = 'bookSavedBig',
    BOOK_SAVED = 'bookSaved',
    EYE_BIG = 'eyeBig',
    EYE_SLASH_BIG = 'eyeSlashBig',
    TASK_BIG = 'taskBig',
    TASK = 'task',
    DOCUMENT_DOWNLOAD = 'documentDownload',
    COMMENTS = 'comments',
    SEND = 'send',
    ACHIEVE = 'achieve',
    COMPANIES = 'companies',
    /* new */
    CATALOG = 'catalog',
    DASHBOARD = 'dashboard',
    GOODS = 'goods',
    HOME = 'home',
    LOGS = 'logs',
    MARKER = 'marker',
    NOTES = 'notes',
    NOTIFICATIONS = 'notifications',
    ORDERS = 'orders',
    PAGES = 'pages',
    SHOPS = 'shops',
    USERS = 'users',
    LOGOUT = 'logout',
    BLOG = 'blog',
    SETTINGS = 'settings',
    SALES = 'sales',
    DOCS = 'docs',
    PDF = 'pdf',
    FILES = 'files',
    DRAG_AND_DROP = 'drag',
    PLUS_BIG = 'plus-big',
    SNACK_SUCCES = 'snack-succes',
    SNACK_STORE = 'snack-store',
    SNACK_COPY = 'snack-copy',
    SNACK_ERROR = 'snack-error',
    SNACK_TRASH = 'snack-trash',
    SNACK_DIAMOND = 'snack-diamond',
    NAV_SEARCH = 'nav-search',
    ARROW_DEFAULT = 'arrow-default',
    DOUBLE_CHECK = 'double-check',
    DOTS = 'dots',
    ORDER_USER = 'order-user',
    ORDER_PHONE = 'order-phone',
    ORDER_TIME = 'order-time',
    ORDER_DELIVERY = 'order-delivery',
    ORDER_MARKER = 'order-marker',
    ORDER_COMMENTES = 'order-comments',
    ORDER_DEVICE = 'order-device',
    STATUS_NEW = 'status-new',
    STATUS_PROCESS = 'status-process',
    STATUS_WORK = 'status-work',
    STATUS_REJECT = 'status-reject',
    STATUS_DELIVERY = 'status-delivery',
    MOBILE_APP = 'mobile-app',
    DESKTOP_WEBSITE = 'desktop-website',
    MOBILE_WEBSITE = 'mobile-website',
    UPPER_CASE = 'upper-case',
    LOWER_CASE = 'lower-case',
    TITLE_CASE = 'title-case',
    CAMEL_CASE = 'camel-case',
    NOTE_CALENDAR = 'note-calendar',
    INFO = 'info'
}
