import { ValueOf } from '../../types/shared';

const EXC = 'exc';

const FORMATS = {
    '1:1': '1000*1000',
    '1:1:icon': '100*100',
    '2:1': '2000*1000',
    '2:1.5': '2000*1500',
    '2:3': '600*900',
    '2.5:1': '2500*1000',
    '3:1': '3000*1000',
    '3:2': '1500*1000',
    '3:3.5': '1285*1500',
    '3:5.5': '815*1500',
    '3.5:2': '2625*1500',
    '3.6:3': '1200*1000',
    '4:3': '2000*1500',
    '4:3.5': '1600*1400',
    '4:4.5': '1330*1500',
    '5:1.5': '3000*900',
    '5.3:4': '1985*1500',
    '6:1': '6000*1000',
    '7:1': '7000*1000',
    '7:2': '3500*1000',
    '7:2.6': '3500*1300',
    '7:3': '2800*1200',
    '8:1': '8000*1000',
    '9:2': '4500*1000',
    '9:8': '1685*1500',
    '9.5:4': '2375*1000',
} as const;

type BaseFormats = keyof typeof FORMATS;

type ExcFormats = ValueOf<{
  [K in BaseFormats]: `${K}:${typeof EXC}`;
}>

export type UploadImageFormats = BaseFormats | ExcFormats;

export const getImageFormatLabel = (format?: UploadImageFormats) => {
    if (!format) return '';

    if (format.endsWith(EXC)) {
        return format.split(`:${EXC}`)[0];
    }

    return `${format} (рекомендуемое разрешение ${FORMATS[format as BaseFormats]})`;
};
