import styled from 'styled-components';
import { colors } from '../../../constants/Colors';
import { units } from '../../../helpers/styles/units';
import { typography } from '../../../helpers/styles/typography';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import Dropdown from '../../../styles/images/filter-products/dropdown.svg';
import { Input } from '../../../components/Input';

export const FilterBlock = styled.div<{isNested?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: ${units(4)};
  background-color: ${colors.grey70};
  border:${({ isNested }) => (isNested ? `1px solid ${colors.grayscale60}` : 'none')};
  padding: ${units(8)};
  position: relative;
  margin-bottom: 8px;
`;

export const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div > div {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     font-weight: ${FontWeight.REGULAR}
     ${typography(5)}
   }
`;

export const Wrapper = styled.div<{isNested: boolean}>`
  position: relative;
  padding-left:${({ isNested }) => (isNested ? '30px' : 'none')};
`;

export const FilterItem = styled.div<{isRange?: boolean}>`
  display: flex;
  align-items: center;
  gap: ${units(4)};
  height: ${units(25)};
  padding: ${units(4)};
  background-color: ${colors.white};
  border: 1px solid ${colors.grayscale60};
  justify-content: space-between;
  margin-bottom: 8px;
    > select {
    -webkit-appearance: none;
    appearance:none;
    border: none;
    border-radius: 0;
    text-overflow: ellipsis;
    background: url(${Dropdown}) no-repeat right ${colors.grayscale01};
    background-position-x: 95%;
    height: ${units(17)};
    width: ${units(100)};
    padding: ${units(4)} !important;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow:ellipsis;
    gap: ${units(2)};
    outline: none;
    ${typography(5)}
      &:first-child {
        width: 35%;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 35%;
      }
    }
    .custom-wrapper {
        width: ${({ isRange }) => (isRange ? '16.5%' : '34%')};
      }
    }
   }
`;

export const FilterControls = styled.div`
  display: flex;
  gap: ${units(2)};
  
  button {
    ${typography(5)};
    padding: ${units(4)};
    border: 1px solid ${colors.greyDark};
    background-color: transparent;
    cursor: pointer; 
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${units(5)};

    &:hover {
    border-color: ${colors.gold};
    color:  ${colors.gold};
      svg {
      stroke:  ${colors.gold};
      }
    }
  }
`;

export const RemoveFilterButton = styled.button`
    all: unset;
    cursor: pointer; 

    &:hover {
      opacity: 0.5;
    }
`;
export const GroupWrapper = styled.div`
position: relative;
`;

export const RemoveGroupButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer; 
    opacity: 1;

    position: absolute;
    top: 18px;
    right: 19px;
    &:hover {
      opacity: 0.5;
    }
`;

export const ErrorMessage = styled.p`
  color: ${colors.error};
  ${typography(12)};
  margin: 0;
  margin-top: ${units(2)};
`;

export const ConditionsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  bottom: -22px;
  left: -8px;
  svg {
  &:nth-child(n + 2) {
    transform: scaleY(-1);
      }
    }
`;

export const Condition = styled.div`
  ${typography(0)};
  color: ${colors.white};
  width: fit-content;
  padding: ${units(2)};
  background-color: ${colors.grey10};
`;

export const StyledInput = styled(Input)`
  height: 34px;
  border: 1px solid ${colors.grayscale100};
  padding: ${units(4)};
`;
