import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Checkbox } from '../../../components/Checkbox';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { routes } from '../../../constants/RouterPath';
import { PageTitle } from '../../../components/PageTitle';
import { UploadPhotoWithMiniature } from '../../../components/UploadPhotoWithMiniature';
import { createSaleBreadcrumbs } from '../../../constants/BreadCrumbs/createSaleBreadcrumbs';
import Switch from '../../../components/sw';
import { CopyToClipboard } from '../../../components/CopyToClipboard';
import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../../components/Icon';
import { SubmitButtons } from '../../../components/StyledComponents';
import {
    CreatePromotionsContract,
    GetPromotionContract,
} from '../../../types/Endpoints/sales/PromitionsContracts';
import { UploadFile } from '../../../types/Endpoints/files/FilesContract';
import {
    selectCurrentPromotion,
    selectGetPromotionLoader,
} from '../../../selectors/sales';
import { useTypeSelector } from '../../../store';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';
import { updatePromotionsThink } from '../../../actions/sales/thunks/updatePromotions';
import { deletePromotionsThink } from '../../../actions/sales/thunks/deletePromotions';
import { config } from '../../../config';
import { addModal } from '../../../actions/modal';
import { getId } from '../../../helpers/generateId';
import { TypesModal } from '../../../types/TypesModalComponent';
import { isEqualObject } from '../../../helpers/isEqual';
import { addNotification } from '../../../actions/notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Textarea } from '../../../components/TextArea';
import { PageTitles } from '../../../constants/PageTitles';
import { getIsoDate, getIsoDateTime, localizeIsoDate } from '../../../helpers/date/getIsoDate';
import * as S from './styles';
import { ColorInput } from '../../../components/ColorInput';
import { TagPreview } from '../../../components/TagPreview';
import { FilterGroup } from '../Filters/FilterGroup';
import { FilterGroupType } from '../Filters/types';

const PATH = `${config.frontStageUrl}stock/`;

export const EditSaleLoader = () => {
    const history = useHistory();
    const { saleId } = useParams<{ saleId: string }>();
    const dispatch = useDispatch();
    const [_, rerender] = useState<boolean>(false);

    const promotion: GetPromotionContract['responseBody'] = useTypeSelector(
        selectCurrentPromotion,
    );

    const [isShownWhileActiveState, setIsShownWhileActiveState] = useState(!!promotion.isShownWhileActive);

    const [filters, setFilters] = useState<FilterGroupType>(promotion.filters ?? {
        condition: 'AND',
        filters: [],
    });

    const validateFilters = () => {
        const isValid = true;
        if (!isValid) {
            alert('Ошибка в условии фильтрации товаров');
        }
    };

    const handleSubmit = (
        values: CreatePromotionsContract<UploadFile>['requestBody'],
    ) => {
        const requestData: CreatePromotionsContract<number>['requestBody'] = {
            globalStatus: values.globalStatus,
            title: values.title,
            urn: values.urn.replace(PATH, ''),
            seoH1: values.seoH1,
            seoDescription: values.seoDescription,
            seoTitle: values.seoTitle,
            shortDescription: values.shortDescription,
            headerImageFileId: values.headerImageFileId?.id || 0,
            headerImageMobileFileId: values.headerImageMobileFileId?.id || 0,
            isShownWhileActive: values.isShownWhileActive,
            startDate:
            isShownWhileActiveState ? getIsoDate(true, values.activeStartDate ? new Date(values.activeStartDate) : new Date()) as string
                : getIsoDate(true, values.startDate ? new Date(values.startDate) : new Date()) as string,
            endDate:
                isShownWhileActiveState ? getIsoDate(true, values.activeEndDate ? new Date(values.activeEndDate) : new Date()) as string
                    : getIsoDate(false, values.endDate ? new Date(values.endDate) : new Date()) as string,
            activeStartDate: getIsoDateTime(values.activeStartDate ? new Date(values.activeStartDate) : new Date()) as string,
            activeEndDate: getIsoDateTime(values.activeEndDate ? new Date(values.activeEndDate) : '') ? getIsoDateTime(values.activeEndDate ? new Date(values.activeEndDate) : '') as string : null,
            bannerStatus: {
                status: values.bannerStatus.status,
                buttonText: values.bannerStatus.buttonText,
            },
            filters: filters.filters?.length ? values.filters : filters,
            tag: {
                color: values.tag.color,
                textColor: values.tag.textColor,
                name: values.tag.name,
                priority: Number(values.tag.priority),
            },
            infoAboutPromotion: values.infoAboutPromotion,
            // bannerStatusInCatalogue: {
            //     status: values.bannerStatusInCatalogue.status,
            //     buttonText: values.bannerStatusInCatalogue.buttonText,
            // },
            additionalDescription: values.additionalDescription,
            isShowOnWeb: values.isShowOnWeb,
            isShowOnMobile: values.isShowOnMobile,
            siteBannerDesktopImageFileId: values.siteBannerDesktopImageFileId?.id || 0,
            siteBannerMobileImageFileId: values.siteBannerMobileImageFileId?.id || 0,
            mobileBannerDesktopImageFileId:
                values.mobileBannerDesktopImageFileId?.id || 0,
            mobileBannerMobileImageFileId:
                values.mobileBannerMobileImageFileId?.id || 0,
        };
        if (values.bannerStatus?.desktopImageFileId?.id) {
            requestData.bannerStatus.desktopImageFileId = values.bannerStatus.desktopImageFileId.id;
        }
        if (values.bannerStatus?.mobileImageFileId?.id) {
            requestData.bannerStatus.mobileImageFileId = values.bannerStatus.mobileImageFileId.id;
        }
        if (values.bannerStatus?.order) {
            requestData.bannerStatus.order = +values.bannerStatus.order;
        }

        // if (values.bannerStatusInCatalogue?.desktopImageFileId?.id) {
        //     requestData.bannerStatusInCatalogue.desktopImageFileId = values.bannerStatusInCatalogue.desktopImageFileId.id;
        // }
        // if (values.bannerStatusInCatalogue?.mobileImageFileId?.id) {
        //     requestData.bannerStatusInCatalogue.mobileImageFileId = values.bannerStatusInCatalogue.mobileImageFileId.id;
        // }

        dispatch(updatePromotionsThink({ data: requestData, saleId }));
    };

    const validationSchema = yup.object().shape({
        title: yup
            .string()
            .max(50, 'Не более 50 символов')
            .required('Поле является обязательным'),
        urn: yup
            .string()
            .test(
                'exception',
                'Поле является обязательным',
                value => !PATH.includes(value || ''),
            ),
        startDate: yup
            .date(),
        activeStartDate: yup
            .date()
            .required('Поле является обязательным'),
        activeEndDate: yup
            .date()
            .nullable()
            .transform((value, originalValue) => (originalValue ? new Date(originalValue) : value))
            .min(
                yup.ref('activeStartDate'),
                'Конец публикации не должен быть раньше начала публикации',
            )
            .typeError('Неверный формат даты'),
        endDate: yup
            .date()
            .min(
                yup.ref('startDate'),
                'Конец действия акции не должен быть раньше начала действия акции',
            ),
        tag: yup.object().shape({
            name: yup
                .string()
                .required('Поле является обязательным'),
            color: yup
                .string()
                .required('Поле является обязательным'),
            textColor: yup
                .string()
                .required('Поле является обязательным'),
            priority: yup
                .number()
                .required('Поле является обязательным'),
        }),
        infoAboutPromotion: yup
            .string()
            .required('Поле является обязательным'),
    });

    const initialValues = {
        globalStatus: promotion.globalStatus,
        title: promotion.title,
        seoH1: promotion.seoH1,
        seoDescription: promotion.seoDescription,
        seoTitle: promotion.seoTitle,
        urn: PATH + promotion.urn,
        isShownWhileActive: promotion.isShownWhileActive ?? false,
        activeStartDate: promotion.activeStartDate ? localizeIsoDate(promotion.activeStartDate) : '',
        activeEndDate: promotion.activeEndDate ? localizeIsoDate(promotion.activeEndDate) : null,
        startDate: promotion.startDate.slice(0, 10),
        endDate: promotion.endDate.slice(0, 10),
        shortDescription: promotion.shortDescription,
        filters: promotion.filters ?? filters,
        tag: {
            color: promotion.tag?.color ?? '#D7B46A',
            textColor: promotion.tag?.textColor ?? '#FFFFFF',
            name: promotion.tag?.name ?? '',
            priority: promotion.tag?.priority ?? 0,
        },
        infoAboutPromotion: promotion.infoAboutPromotion ?? '',
        headerImageFileId: promotion.headerImageFile,
        headerImageMobileFileId: promotion.headerImageMobileFile,
        bannerStatus: {
            status: promotion.bannerStatus.status,
            buttonText: promotion.bannerStatus.buttonText,
            order: promotion.bannerStatus.order,
            desktopImageFileId: promotion.bannerStatus.desktopImageFile,
            mobileImageFileId: promotion.bannerStatus.mobileImageFile,
        },
        bannerStatusInCatalogue: {
            status: promotion.bannerStatusInCatalogue?.status,
            buttonText: promotion.bannerStatusInCatalogue?.buttonText,
            desktopImageFileId: promotion.bannerStatusInCatalogue?.desktopImageFile,
            mobileImageFileId: promotion.bannerStatusInCatalogue?.mobileImageFile,
        },
        additionalDescription: promotion.additionalDescription,
        isShowOnWeb: promotion.isShowOnWeb,
        isShowOnMobile: promotion.isShowOnMobile,
        siteBannerDesktopImageFileId: promotion.siteBannerDesktopImageFile,
        siteBannerMobileImageFileId: promotion.siteBannerMobileImageFile,
        mobileBannerDesktopImageFileId: promotion.mobileBannerDesktopImageFile,
        mobileBannerMobileImageFileId: promotion.mobileBannerMobileImageFile,
    };

    const form = useFormik<CreatePromotionsContract<UploadFile>['requestBody']>({
        onSubmit: handleSubmit,
        initialValues:
            initialValues as CreatePromotionsContract<UploadFile>['requestBody'],
        validationSchema,
    });

    const handleFiltersChange = (updatedFilters: FilterGroupType) => {
        setFilters(updatedFilters);
        form.setFieldValue('filters', updatedFilters);
    };

    const handleToggle1 = (value: 'ACTIVE' | 'INACTIVE') => {
        form.values.bannerStatus.status = value;
        rerender(prev => !prev);
    };

    const handleResetForm = (
        values: CreatePromotionsContract<UploadFile>['requestBody'],
    ) => {
        history.push(routes.sales);
        const equal = isEqualObject(initialValues, values);
        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>{PageTitles.SALES}</PageTitle>
                <Breadcrumbs breadcrumbs={createSaleBreadcrumbs('Редактировать')} />
                <S.Root>
                    <S.MainWrapper>
                        <S.FlexWrapper>
                            <S.Subtitle>Отображение акции</S.Subtitle>
                            <Switch
                                name="isActive"
                                value={form.values.globalStatus === 'ACTIVE'}
                                onChange={() => {
                                    form.values.globalStatus = form.values.globalStatus === 'ACTIVE'
                                        ? 'INACTIVE'
                                        : 'ACTIVE';
                                    rerender(prev => !prev);
                                }}
                                type="checkbox"
                                title
                            />
                        </S.FlexWrapper>

                        <S.IdInfo>SEO</S.IdInfo>
                        <Textarea
                            name="seoH1"
                            onChange={form.handleChange}
                            value={form.values.seoH1}
                            placeholder="H1"
                            maxLength={255}
                            title="H1"
                            isTouched={form.touched.seoH1}
                            error={form.errors.seoH1}
                        />
                        <Textarea
                            name="seoTitle"
                            onChange={form.handleChange}
                            value={form.values.seoTitle}
                            placeholder="Title"
                            maxLength={255}
                            title="Title"
                            isTouched={form.touched.seoTitle}
                            error={form.errors.seoTitle}
                        />
                        <Textarea
                            name="seoDescription"
                            onChange={form.handleChange}
                            value={form.values.seoDescription}
                            placeholder="Description"
                            maxLength={255}
                            title="Description"
                            isTouched={form.touched.seoDescription}
                            error={form.errors.seoDescription}
                        />
                    </S.MainWrapper>
                    <S.CheckboxWrapper>
                        <S.IdInfo>Основное</S.IdInfo>
                        <S.StyledInput
                            name="title"
                            onChange={form.handleChange}
                            value={form.values.title}
                            placeholder="Заголовок (не более 50 символов)*"
                            typeInput="text"
                            maxLength={255}
                            title="Заголовок (не более 50 символов)*"
                            isTouched={form.touched.title}
                            error={form.errors.title}
                            count={form.submitCount}
                        />

                        <S.StyledInput
                            name="id"
                            value={promotion.id}
                            typeInput="text"
                            title="ID"
                            readonly
                        />

                        <S.UrlWrapper>
                            <S.InputWithPlaceholder
                                name="urn"
                                onChange={form.handleChange}
                                value={form.values.urn.length < PATH.length ? PATH : form.values.urn}
                                typeInput="text"
                                title="URL*"
                                isTouched={form.touched.urn}
                                error={form.errors.urn}
                                count={form.submitCount}
                            />
                            <S.CopyWrapper>
                                <CopyToClipboard copyText={form.values.urn.length < PATH.length ? PATH : form.values.urn} />
                            </S.CopyWrapper>
                        </S.UrlWrapper>
                        <S.CheckboxWrapper>
                            <Checkbox
                                name="isShownWhileActive"
                                title="Активность = сроку действия"
                                checked={form.values.isShownWhileActive}
                                onChange={e => {
                                    const newState = !form.values.isShownWhileActive;
                                    setIsShownWhileActiveState(newState);

                                    if (!newState) {
                                        form.setFieldValue('startDate', '');
                                        form.setFieldValue('endDate', '');
                                    }
                                    form.handleChange(e);
                                }}
                            />
                        </S.CheckboxWrapper>
                        <S.DateWrapper>
                            <S.StyledInput
                                name="activeStartDate"
                                onChange={form.handleChange}
                                value={
                                    typeof form.values.activeStartDate === 'string'
                                        ? form.values.activeStartDate
                                        : ''
                                }
                                placeholder="Начало публикации"
                                typeInput="datetime-local"
                                title="Начало публикации"
                                isTouched={form.touched.activeStartDate}
                                error={form.errors.activeStartDate}
                                count={form.submitCount}
                            />

                            <S.StyledInput
                                name="activeEndDate"
                                onChange={form.handleChange}
                                value={
                                    typeof form.values.activeEndDate === 'string'
                                        ? form.values.activeEndDate
                                        : ''
                                }
                                placeholder="Конец публикации"
                                typeInput="datetime-local"
                                title="Конец публикации"
                                isTouched={form.touched.activeEndDate}
                                error={form.errors.activeEndDate}
                                count={form.submitCount}
                            />
                        </S.DateWrapper>

                        {
                            !isShownWhileActiveState
                        && (

                            <S.DateWrapper>
                                <S.StyledInput
                                    name="startDate"
                                    onChange={form.handleChange}
                                    value={
                                        typeof form.values.startDate === 'string'
                                            ? form.values.startDate
                                            : ''
                                    }
                                    placeholder="Дата начала"
                                    typeInput="date"
                                    title="Дата начала"
                                    isTouched={form.touched.startDate}
                                    error={form.errors.startDate}
                                    count={form.submitCount}
                                />

                                <S.StyledInput
                                    name="endDate"
                                    onChange={form.handleChange}
                                    value={
                                        typeof form.values.endDate === 'string' && !isShownWhileActiveState
                                            ? form.values.endDate
                                            : ''
                                    }
                                    placeholder="Дата окончания"
                                    typeInput="date"
                                    title="Дата окончания"
                                    isTouched={form.touched.endDate}
                                    error={form.errors.endDate}
                                    count={form.submitCount}
                                />
                            </S.DateWrapper>
                        )
                        }
                        <S.StyledInput
                            name="shortDescription"
                            onChange={form.handleChange}
                            value={form.values.shortDescription}
                            placeholder="Краткое описание (не более 60 символов)"
                            typeInput="text"
                            maxLength={255}
                            title="Краткое описание (не более 60 символов)"
                            isTouched={form.touched.shortDescription}
                            error={form.errors.shortDescription}
                        />
                    </S.CheckboxWrapper>
                    <S.MainWrapper>
                        <S.Subtitle>Баннер в списке акций на сайте</S.Subtitle>
                        <UploadPhotoWithMiniature
                            name="headerImageFileId"
                            title="Изображение для desktop версии"
                            format="7:2.6"
                            onChange={form.setFieldValue}
                            value={form.values.headerImageFileId}
                            maxSize={3}
                        />
                        <UploadPhotoWithMiniature
                            name="headerImageMobileFileId"
                            title="Изображение для мобильной версии"
                            format="3.5:2"
                            onChange={form.setFieldValue}
                            value={form.values.headerImageMobileFileId}
                            maxSize={3}
                        />
                    </S.MainWrapper>

                    <S.CheckboxWrapper>
                        <S.FlexWrapper>
                            <S.Subtitle>Статус отображения в баннере</S.Subtitle>
                            <Switch
                                name="isShowOnBanner"
                                value={form.values.bannerStatus.status === 'ACTIVE'}
                                onChange={() => handleToggle1(
                                    form.values.bannerStatus.status === 'ACTIVE'
                                        ? 'INACTIVE'
                                        : 'ACTIVE',
                                )}
                                type="checkbox"
                                title
                            />
                        </S.FlexWrapper>

                        {form.values.bannerStatus.status === 'ACTIVE' && (
                            <S.CheckboxWrapper>
                                <S.StyledInput
                                    name="bannerStatus.buttonText"
                                    onChange={form.handleChange}
                                    value={form.values.bannerStatus.buttonText}
                                    placeholder="Текст кнопки"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Текст кнопки"
                                    isTouched={form?.touched?.bannerStatus?.buttonText}
                                    error={form?.errors?.bannerStatus?.buttonText}
                                />

                                <S.OrderWrapper>
                                    <S.StyledInput
                                        name="bannerStatus.order"
                                        onChange={form.handleChange}
                                        value={form.values.bannerStatus.order}
                                        placeholder="Сортировка"
                                        typeInput="text"
                                        maxLength={255}
                                        title="Сортировка"
                                        isTouched={form?.touched?.bannerStatus?.order}
                                        error={form?.errors?.bannerStatus?.order}
                                    />
                                </S.OrderWrapper>

                                <UploadPhotoWithMiniature
                                    name="bannerStatus.desktopImageFileId"
                                    title="Изображение для desktop версии"
                                    format="9.5:4"
                                    onChange={form.setFieldValue}
                                    value={form.values.bannerStatus.desktopImageFileId}
                                    maxSize={4}
                                />
                                <UploadPhotoWithMiniature
                                    name="bannerStatus.mobileImageFileId"
                                    title="Изображение для мобильной версии"
                                    format="2:1"
                                    onChange={form.setFieldValue}
                                    value={form.values.bannerStatus.mobileImageFileId}
                                    maxSize={3}
                                />
                            </S.CheckboxWrapper>
                        )}
                    </S.CheckboxWrapper>

                    <S.CheckboxWrapper>
                        <S.FlexWrapper>
                            <S.Subtitle>
                                Статус отображения в баннере в «Каталоге»
                            </S.Subtitle>
                            <S.Dicription>
                                Для загрузки в «Каталоге» доступна только одна акция
                            </S.Dicription>
                            <Switch
                                name="isShowOnBannerCatalog"
                                value={form.values.bannerStatusInCatalogue?.status === 'ACTIVE'}
                                onChange={() => {
                                    form.values.bannerStatusInCatalogue.status = form.values.bannerStatusInCatalogue?.status === 'ACTIVE'
                                        ? 'INACTIVE'
                                        : 'ACTIVE';
                                    rerender(prev => !prev);
                                }}
                                type="checkbox"
                                title
                            />
                        </S.FlexWrapper>

                        {form.values.bannerStatusInCatalogue?.status === 'ACTIVE' && (
                            <S.CheckboxWrapper>
                                <S.StyledInput
                                    name="bannerStatusInCatalogue.buttonText"
                                    onChange={form.handleChange}
                                    value={form.values.bannerStatusInCatalogue?.buttonText}
                                    placeholder="Текст кнопки"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Текст кнопки"
                                    isTouched={form?.touched?.bannerStatusInCatalogue?.buttonText}
                                    error={form.errors.bannerStatusInCatalogue?.buttonText}
                                />

                                <UploadPhotoWithMiniature
                                    name="bannerStatusInCatalogue.desktopImageFileId"
                                    title="Баннер в каталог для desktop версии сайта"
                                    format="6:1"
                                    onChange={form.setFieldValue}
                                    value={
                                        form.values.bannerStatusInCatalogue?.desktopImageFileId
                                    }
                                    maxSize={3}
                                />
                                <UploadPhotoWithMiniature
                                    name="bannerStatusInCatalogue.mobileImageFileId"
                                    title="Баннер в каталог  для мобильной версии сайта"
                                    format="2:1.5"
                                    onChange={form.setFieldValue}
                                    value={form.values.bannerStatusInCatalogue?.mobileImageFileId}
                                    maxSize={3}
                                />
                            </S.CheckboxWrapper>
                        )}
                    </S.CheckboxWrapper>

                    <S.MainWrapper>
                        <S.StyledTextEditor
                            name="additionalDescription"
                            onChange={form.setFieldValue}
                            valueEditor={form.values.additionalDescription}
                            title="Дополнительное описание"
                            withoutPictures={false}
                        />
                    </S.MainWrapper>

                    <S.MainWrapper>
                        <S.Subtitle>Связанные товары</S.Subtitle>
                        <FilterGroup group={filters} onChange={handleFiltersChange} />
                    </S.MainWrapper>

                    <S.FlexWrapper>
                        <S.Subtitle>Метка акции</S.Subtitle>
                        <S.StyledInput
                            name="tag.name"
                            onChange={form.handleChange}
                            value={form.values.tag.name}
                            placeholder="Имя метки*"
                            typeInput="text"
                            title="Имя метки*"
                            error={form.errors.tag?.name}
                            count={form.submitCount}
                        />
                        <ColorInput
                            name="tag.color"
                            title="Цвет фона метки"
                            value={form.values.tag.color}
                            onChange={form.handleChange}
                            error={form.errors.tag?.color}
                            count={form.submitCount}
                        />
                        <ColorInput
                            name="tag.textColor"
                            title="Цвет текста метки"
                            value={form.values.tag.textColor}
                            onChange={form.handleChange}
                            error={form.errors.tag?.textColor}
                            count={form.submitCount}
                        />
                        <TagPreview
                            name={form.values.tag.name}
                            bgColor={form.values.tag.color}
                            textColor={form.values.tag.textColor}
                        />
                        <S.NumberInputWrapper>
                            <S.StyledInput
                                name="tag.priority"
                                onChange={form.handleChange}
                                value={form.values.tag?.priority}
                                placeholder="Приоритет акции"
                                typeInput="number"
                                title="Приоритет акции"
                                error={form.errors.tag?.priority}
                                count={form.submitCount}
                            />
                        </S.NumberInputWrapper>
                    </S.FlexWrapper>

                    <S.MainWrapper>
                        <S.StyledTextEditor
                            name="infoAboutPromotion"
                            onChange={form.setFieldValue}
                            valueEditor={form.values.infoAboutPromotion}
                            title="Информация об акции"
                            withoutPictures={false}
                            error={form.errors.infoAboutPromotion}
                            count={form.submitCount}
                            isTouched={form.touched.infoAboutPromotion}
                        />
                    </S.MainWrapper>

                    <S.CheckboxWrapper>
                        <S.IdInfo>Отображение</S.IdInfo>
                        <Checkbox
                            name="isShowOnWeb"
                            title="Отображать на сайте"
                            checked={form.values.isShowOnWeb}
                            onChange={form.handleChange}
                        />

                        <Checkbox
                            name="isShowOnMobile"
                            title="Отображать в мобильном приложении"
                            checked={form.values.isShowOnMobile}
                            onChange={form.handleChange}
                        />
                    </S.CheckboxWrapper>
                    <S.CheckboxWrapper>
                        <S.Subtitle>Баннер внутри акции на сайте</S.Subtitle>
                        <UploadPhotoWithMiniature
                            name="siteBannerDesktopImageFileId"
                            title="Изображение для desktop версии"
                            format="6:1"
                            onChange={form.setFieldValue}
                            value={form.values.siteBannerDesktopImageFileId}
                            maxSize={4}
                        />
                        <UploadPhotoWithMiniature
                            name="siteBannerMobileImageFileId"
                            title="Изображение для мобильной версии"
                            format="3:1:exc"
                            onChange={form.setFieldValue}
                            value={form.values.siteBannerMobileImageFileId}
                            maxSize={3}
                        />
                    </S.CheckboxWrapper>

                    <S.CheckboxWrapper>
                        <S.Subtitle>
                            Баннер к акции в мобильном приложении
                        </S.Subtitle>
                        <UploadPhotoWithMiniature
                            name="mobileBannerDesktopImageFileId"
                            title="Баннер в списке акций"
                            format="2:1:exc"
                            onChange={form.setFieldValue}
                            value={form.values.mobileBannerDesktopImageFileId}
                            maxSize={3}
                        />
                        <UploadPhotoWithMiniature
                            name="mobileBannerMobileImageFileId"
                            title="Баннер внутри акции "
                            format="2:1:exc"
                            onChange={form.setFieldValue}
                            value={form.values.mobileBannerMobileImageFileId}
                            maxSize={3}
                        />
                    </S.CheckboxWrapper>

                    <S.DeleteButton
                        typeButton={TypesButton.DELETE}
                        size={SizesButton.M}
                        onClick={() => {
                            dispatch(
                                addModal({
                                    id: getId()(),
                                    type: TypesModal.CONFIRM,
                                    message: {
                                        description: 'Вы действительно хотите удалить?',
                                    },
                                    onSuccessMessage: 'Удалить',
                                    onSuccess: () => {
                                        dispatch(deletePromotionsThink(saleId));
                                    },
                                    withCloseIcon: true,
                                }),
                            );
                        }}
                    >
                        <Icon
                            type={TypesIcon.TRASH_BIG}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                        />
                        Удалить
                    </S.DeleteButton>
                </S.Root>

            </S.Wrapper>
            <SubmitButtons
                reset={() => handleResetForm(form.values)}
                submit={form.submitForm}
            />
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetPromotionLoader,
});

export const EditSaleLoaderWithLoader = loader(EditSaleLoader);
