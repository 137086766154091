import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FilterItem } from './FilterItem';
import { FilterGroupType, FilterCondition } from './types';
import * as S from './styles';
import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../../components/Icon';
import { RadioGroup } from '../../../components/RadioButtonGroup';
import { colors } from '../../../constants/Colors';
import { useTypeSelector } from '../../../store';
import { getCatalogCategories } from '../../../actions/category';
import { getAllFilters } from '../../../actions/filters/thunks';
import { selectCategories } from '../../../selectors/catalog';
import { selectAllFilters } from '../../../selectors/filters';

enum Conditions {
    AND = 'И',
    OR = 'ИЛИ',
  }

type FilterGroupProps = {
  group: FilterGroupType;
  onChange: (updatedGroup: FilterGroupType) => void;
  isNested?: boolean;
};

const isFilterGroup = (filter: any): filter is FilterGroupType => 'condition' in filter && 'filters' in filter;

export const FilterGroup: React.FC<FilterGroupProps> = ({ group, onChange, isNested }) => {
    const dispatch = useDispatch();

    const filters = useTypeSelector(selectAllFilters);
    const categories = useTypeSelector(selectCategories);

    useEffect(() => {
        if (!filters) {
            dispatch(getAllFilters({}));
        }
        if (!categories) {
            dispatch(getCatalogCategories());
        }
    }, []);

    const addFilter = () => {
        const newFilter: FilterCondition = {
            id: 0, type: 'CHECK_BOX', name: 'Выберите фильтр', operator: 'equals', value: undefined, valueNumber: undefined, propertyValues: [],
        };
        onChange({
            ...group,
            filters: [...group.filters, newFilter],
        });
    };

    const removeGroup = (index: number) => {
        const updatedFilters = group.filters.filter((_, i) => i !== index);
        onChange({ ...group, filters: updatedFilters });
    };
    const addGroup = () => {
        const newGroup: FilterGroupType = { condition: 'AND', filters: [] };
        onChange({
            ...group,
            filters: [...group.filters, newGroup],
        });
    };

    const updateFilter = (index: number, updatedFilter: FilterCondition | FilterGroupType) => {
        const updatedFilters = [...group.filters];
        updatedFilters[index] = updatedFilter;
        onChange({ ...group, filters: updatedFilters });
    };

    const removeFilter = (index: number) => {
        const updatedFilters = group.filters.filter((_, i) => i !== index);
        onChange({ ...group, filters: updatedFilters });
    };
    return (
        <S.FilterBlock isNested={isNested}>
            {
                (!!group.filters.length || isNested)
            && (
                <S.BlockHeader>
                    <RadioGroup
                        name="condition"
                        controls={[
                            { title: 'Все условия', value: 'AND' },
                            { title: 'Любое условие', value: 'OR' },
                        ]}
                        onChange={(_field, value) => {
                            onChange({ ...group, condition: value as 'AND' | 'OR' });
                        }}
                        fieldValue={group.condition ?? 'AND'}
                        blockTitle=""
                    />
                </S.BlockHeader>
            )
            }
            <div id="group">
                {group.filters.map((filter, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <S.Wrapper isNested={group.filters.length > 1} key={index}>
                        {isFilterGroup(filter) ? (
                            <S.GroupWrapper>
                                <FilterGroup
                                    group={filter}
                                    isNested
                                    onChange={updatedGroup => updateFilter(index, updatedGroup)}
                                />

                                <S.RemoveGroupButton type="button" onClick={() => removeGroup(index)}>
                                    <Icon
                                        type={TypesIcon.TRASH_BIG}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    />
                                </S.RemoveGroupButton>
                            </S.GroupWrapper>
                        ) : (
                            <FilterItem
                                filter={filter}
                                onChange={updatedFilter => updateFilter(index, updatedFilter)}
                                onRemove={() => removeFilter(index)}
                                categories={categories ?? []}
                                filters={filters ?? { filters: [] }}
                            />
                        )}
                        {index < group.filters.length - 1 && (
                            <S.ConditionsWrapper>
                                <Icon
                                    type={TypesIcon.FILTER_LINE}
                                    width="38px"
                                    height="10px"
                                    viewBox="0 0 38 10"
                                    color={colors.grayscale60}
                                />
                                <S.Condition>{Conditions[group.condition]}</S.Condition>
                                <Icon
                                    type={TypesIcon.FILTER_LINE}
                                    width="38px"
                                    height="10px"
                                    viewBox="0 0 38 10"
                                    color={colors.grayscale60}
                                />

                            </S.ConditionsWrapper>
                        )}
                    </S.Wrapper>
                ))}
            </div>
            <S.FilterControls>
                <button type="button" onClick={addFilter}>
                    <Icon
                        type={TypesIcon.FILTER_BIG}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />
                    Добавить фильтр
                </button>
                <button type="button" onClick={addGroup}>
                    <Icon
                        type={TypesIcon.ROWS}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />
                    Добавить группу
                </button>
            </S.FilterControls>
        </S.FilterBlock>
    );
};
