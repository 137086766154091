import React, { useMemo } from 'react';
import * as S from './styles';
import { checkBoxOperators, FilterCondition, rangeOperators } from './types';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { AllFilterItem } from '../../../types/Endpoints/filters/FiltersContracts';
import { CatalogCategories } from '../../../types/Endpoints/categories/CategoriesContracts';

enum FilterOperators {
    equals='равно',
    not_equals='не равно',
    range='диапазон',
    greater_than='больше',
    less_than='меньше'
}

type FilterItemProps = {
  filter: FilterCondition;
  onChange: (updatedFilter: FilterCondition) => void;
  onRemove: () => void;
  categories: CatalogCategories[];
  filters: {filters: AllFilterItem[]};
};

export const FilterItem: React.FC<FilterItemProps> = ({
    filter, onChange, onRemove, categories, filters,
}) => {
    const transformedCategories = useMemo(() => categories?.map(item => ({
        value: item.name,
        valueNumber: item.id,
    })), [categories]);

    console.log(filter);
    const categoryFilter: FilterCondition = {
        id: 20000,
        type: 'CHECK_BOX',
        name: 'Категория',
        operator: 'equals',
        value: 'Наручные часы',
        valueNumber: 15,
        propertyValues: transformedCategories ?? [],
    };

    const discountFilter: FilterCondition = {
        id: 20001, type: 'RANGE', name: 'Скидка (%)', operator: 'equals', value: undefined, valueNumber: undefined, propertyValues: [],
    };

    const priceFilter: FilterCondition = {
        id: 20002, type: 'RANGE', name: 'Цена', operator: 'equals', value: undefined, valueNumber: undefined, propertyValues: [],
    };

    const allFilters = [categoryFilter, discountFilter, priceFilter, ...filters?.filters ?? []];

    const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedElement = allFilters?.find(element => element.id.toString() === e.target.value);
        if (!selectedElement) {
            return;
        }
        const firstOperator = selectedElement.type === 'RANGE' ? rangeOperators[0] : checkBoxOperators[0];

        const firstPropertyValue = selectedElement.propertyValues?.[0];

        const updatedFilter = {
            ...filter,
            id: selectedElement.id,
            type: selectedElement?.type,
            name: selectedElement?.name,
            propertyValues: selectedElement?.propertyValues,
            operator: firstOperator,
            value: firstPropertyValue?.value || '',
            valueNumber: selectedElement.id === 20000 ? firstPropertyValue?.valueNumber : null,
        };
        onChange(updatedFilter);
    };

    const handleOperatorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const updatedFilter = {
            ...filter,
            operator: e.target.value,
        };
        onChange(updatedFilter);
    };

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const updatedFilter = {
            ...filter,
            value: e.target.value,
            valueNumber: null,
        };
        onChange(updatedFilter);
    };

    const handleNumberValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const updatedFilter = {
            ...filter,
            valueNumber: e.target.value ? parseFloat(e.target.value) : null,
            value: null,
        };
        onChange(updatedFilter);
    };
    const handleCategoryIdValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const updatedFilter = {
            ...filter,
            valueNumber: Number(e.target.value),
            value: undefined,
        };
        onChange(updatedFilter);
    };

    const handleRangeValueChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const rangeValues = Array.isArray(filter.value) ? [...filter.value] : [null, null];
        rangeValues[index] = e.target.value ? parseFloat(e.target.value) : null;

        onChange({ ...filter, value: rangeValues });
    };

    return (
        <S.FilterItem isRange={filter.operator === 'range'}>
            <select value={filter.id} onChange={handleFilterChange}>
                <option value="">
                    {filter.name ?? 'Выберите фильтр'}
                </option>
                {allFilters.map(filterOption => (
                    <option key={filterOption.id} value={filterOption.id}>
                        {filterOption.name}
                    </option>
                ))}
            </select>
            {
                filter.id !== 0 && (
                    <>
                        <select value={filter.operator} onChange={handleOperatorChange}>
                            {filter.type === 'RANGE' ? rangeOperators.map(operator => (
                                <option key={operator} value={operator}>
                                    {FilterOperators[operator]}
                                </option>
                            )) : checkBoxOperators.map(operator => (
                                <option key={operator} value={operator}>
                                    {FilterOperators[operator]}
                                </option>
                            ))}
                        </select>
                        {
                            filter.type === 'RANGE' && (
                                filter.operator === 'range' ? (
                                    <>
                                        <S.StyledInput
                                            name="value"
                                            value={filter.value?.[0] || ''}
                                            onChange={e => handleRangeValueChange(e, 0)}
                                            typeInput="number"
                                        />
                                        <p>-</p>
                                        <S.StyledInput
                                            name="value"
                                            value={filter.value?.[1] || ''}
                                            onChange={e => handleRangeValueChange(e, 1)}
                                            typeInput="number"
                                        />
                                    </>
                                )
                                    : (
                                        <S.StyledInput
                                            name="string"
                                            value={filter.valueNumber ?? ''}
                                            onChange={e => handleNumberValueChange(e)}
                                            typeInput="number"
                                        />
                                    )
                            )
                        }

                        {
                            filter.id === 20000 && (
                                <select onChange={handleCategoryIdValueChange} value={filter.valueNumber ?? ''}>
                                    {filter.propertyValues.map(({ value, valueNumber }, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <option key={index} value={valueNumber}>
                                            {value}
                                        </option>
                                    ))}
                                </select>
                            )
                        }
                        {filter.type !== 'RANGE' && filter.id !== 20000 && (
                            <select
                                value={filter.value ?? filter.valueNumber}
                                onChange={e => {
                                    // Check if the selected option is a number value
                                    const selectedValue = e.target.value;
                                    const isNumberValue = filter.propertyValues.some(prop => prop.valueNumber === Number(selectedValue));

                                    if (isNumberValue) {
                                        handleNumberValueChange(e);
                                    } else {
                                        handleValueChange(e);
                                    }
                                }}
                            >
                                {filter.propertyValues.map(({ value, valueNumber }, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <option key={index} value={value ?? valueNumber}>
                                        {value ?? valueNumber}
                                    </option>
                                ))}
                            </select>
                        )}
                    </>

                )
            }

            <S.RemoveFilterButton type="button" onClick={onRemove}>
                <Icon
                    type={TypesIcon.TRASH_BIG}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                />
            </S.RemoveFilterButton>

        </S.FilterItem>
    );
};
