import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';

interface TagPreviewProps {
  name?: string;
  bgColor: string;
  textColor: string;
}

const TagPreviewWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 8px;
height: ${units(15)};
`;

const Tag = styled.div<TagPreviewProps>`
width: fit-content;
padding: 3px 8px;
text-align: center;
font-weight: ${FontWeight.REGULAR};

background-color: ${({ bgColor }) => bgColor || 'transparent'};
color: ${({ textColor }) => textColor || 'black'};

${typography(2)};

`;

export const TagPreview = ({ name, bgColor, textColor }: TagPreviewProps) => (
    <TagPreviewWrapper>
        Предпросмотр метки:
        {name && (
            <Tag bgColor={bgColor} textColor={textColor}>{name}</Tag>
        )}
    </TagPreviewWrapper>
);
