import { createReducer } from '@reduxjs/toolkit';

import { CatalogCategories, CatalogCategoryItem } from '../../types/Endpoints/categories/CategoriesContracts';
import { getFilter, getFilters, getAllFilters } from '../../actions/filters';
import { GetFilterContract, GetFiltersContract, GetAllFiltersContract } from '../../types/Endpoints/filters/FiltersContracts';
import { PropertiesListItem } from '../../types/Endpoints/properties/PropertiesContracts';
import { getProperties } from '../../actions/properties';
import { getCatalogCategories, getCatalogCategory } from '../../actions/category/thunks';

interface CatalogState {
    categories?: CatalogCategories[];
    selectedCategory?: CatalogCategoryItem;
    filters?: GetFiltersContract['responseBody'];
    allFilters?: GetAllFiltersContract['responseBody'];
    filter?: GetFilterContract['responseBody'];
    properties?: PropertiesListItem[];
}

export const catalog = createReducer<CatalogState>({}, builder => {
    builder.addCase(getCatalogCategories.fulfilled, (state, { payload }) => ({
        ...state,
        categories: payload,
    }));
    builder.addCase(getCatalogCategory.fulfilled, (state, { payload }) => ({
        ...state,
        selectedCategory: payload,
    }));
    builder.addCase(getFilter.fulfilled, (state, { payload }) => ({
        ...state,
        filter: payload,
    }));
    builder.addCase(getFilters.fulfilled, (state, { payload }) => ({
        ...state,
        filters: payload,
    }));
    builder.addCase(getAllFilters.fulfilled, (state, { payload }) => ({
        ...state,
        allFilters: payload,
    }));
    builder.addCase(getProperties.fulfilled, (state, { payload }) => ({
        ...state,
        properties: payload,
    }));
});
