import ReactQuill, { Quill } from 'react-quill';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ImageResize from 'quill-image-resize-module-react';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { Title } from '../Title';
import 'react-quill/dist/quill.snow.css';

Quill.register('modules/imageResize', ImageResize);
Quill.register(Quill.import('attributors/style/align'), true);

const Blockquote = Quill.import('formats/blockquote');
Blockquote.className = 'quill_blockquote';
Quill.register(Blockquote, true);

const List = Quill.import('formats/list');
List.className = 'quill_list';
Quill.register(List, true);

const Link = Quill.import('formats/link');
Link.className = 'quill_link';
Quill.register(Link, true);

const Parchment = Quill.import('parchment');

class IndentAttributor extends Parchment.Attributor.Style {
    add(node: any, value: any) {
        value = parseInt(value, 10);
        if (value === 0) {
            this.remove(node);
            return true;
        }
        return super.add(node, `${value}em`);
    }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const IndentStyle = new IndentAttributor('indent', 'text-indent', {
    scope: Parchment.Scope.BLOCK,
    whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em'],
});

Quill.register(IndentStyle, true);

interface TextEditorProps {
  name: string;
  title?: string;
  onChange?: (field: string, value: string, shouldValidate?: boolean) => void;
  valueEditor?: string;
  withoutPictures?: boolean;
  className?: string;
  id?: string;
  error?: string;
  isTouched?: boolean;
  count?: number;
}

const StyledEditorWrapper = styled.div`
  width: 100%;
  margin-bottom: ${units(6)};

  & .ql-toolbar.ql-snow {
    border-radius: ${units(3)} ${units(3)} 0 0;
    border: 1px solid ${colors.grayscale05};

    .ql-formats {
        margin-right: 0;
    }
  }

  & .ql-container.ql-snow {
    border-radius: 0 0 ${units(3)} ${units(3)};
    border: 1px solid ${colors.grayscale05};

    & .ql-editor {
      min-height: ${units(80)};
    }
  }
`;

const StyledError = styled.div`
  z-index: 100;

  display: flex;

  font-size: ${units(7)};

  color: ${colors.red};

  margin-top: ${units(2)};
  min-height: ${units(6)};
  min-width: ${units(6)};
}
`;

const StyledTitle = styled(Title)`
  margin-bottom: ${units(2)};
`;

export const TextEditor = ({
    id,
    name,
    title,
    valueEditor,
    onChange,
    withoutPictures = false,
    className,
    error,
    isTouched,
    count,
}: TextEditorProps) => {
    const [isError, setError] = useState(isTouched && Boolean(error));

    useEffect(() => {
        if (count) {
            setError(Boolean(error));
        }
    }, [count, error]);

    const handleChange = useCallback(
        (value: string) => {
            if (onChange) {
                onChange(name, value, false);
            }
        },
        [name, onChange],
    );

    const modules = {
        clipboard: {
            matchVisual: false,
        },
        toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            [
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                { script: 'sub' },
                { script: 'super' },
            ],
            [
                {
                    color: [
                        '#393836',
                        '#656565',
                        '#939393',
                        '#C7C7C7',
                        '#E7E6E6',
                        '#F2F2F2',
                        '#F7F7F7',
                        '#FFFFFF',
                        '#BB3434',
                        '#D7B46A',
                        '#B89D65',
                        '#6DB180',
                        '#587EC6',
                    ],
                },
                {
                    background: [
                        '#393836',
                        '#656565',
                        '#939393',
                        '#C7C7C7',
                        '#E7E6E6',
                        '#F2F2F2',
                        '#F7F7F7',
                        '#FFFFFF',
                        '#BB3434',
                        '#D7B46A',
                        '#B89D65',
                        '#6DB180',
                        '#587EC6',
                    ],
                },
            ],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
                { align: [] },
            ],
            !withoutPictures ? ['link', 'image', 'clean'] : ['clean'],
        ],
        imageResize: {
            parchment: Parchment,
            modules: ['Resize', 'DisplaySize'],
        },
    };

    const formats = withoutPictures
        ? [
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'color',
            'background',
            'align',
            'script',
        ]
        : [
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'color',
            'background',
            'align',
            'script',
        ];

    useEffect(() => {
        valueEditor?.match(/<img.+?>/g)?.forEach(item => {
            if (item.includes('width')) {
                const img = document.querySelector(`img[${item.match(/src.+?\s/)?.[0]}]`);
                img?.setAttribute('width', item.replace(/.+?width="(\d+)".*/, '$1'));
            }
        });
    }, []);

    return (
        <StyledEditorWrapper className={className}>
            <StyledTitle>{title}</StyledTitle>
            <ReactQuill
                id={id}
                theme="snow"
                value={valueEditor}
                onChange={handleChange}
                modules={modules}
                formats={formats}
            />
            {isError && <StyledError>{error}</StyledError>}
        </StyledEditorWrapper>
    );
};
